<template>
    <div class="container">
        <span class="welcome">欢迎您:{{ user }}</span>
        <span class="exit" @click="exitLogin()">退123出</span>
    </div>
</template>

<script>
export default {
    data() {
        return {
            user:""
        }
    },
    methods: {
        exitLogin:function(){
            this.$confirm('是否确认退出?', '商能科技提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
            }).then(() => {
                localStorage.clear();
                this.$router.go(0);
            }).catch(() => {
                return false;         
            });
        },
    },
    mounted() {
        this.user = this.$store.state.phone;
    },
}
</script>

<style scoped>
    *{
        margin: 0;
        padding: 0;
    }
    .container{
        width: 80%;
        margin-left: 10%;
        position: relative;
        top: 0;
        bottom: 0;
        overflow: hidden;
    }
    .welcome{
        font-size: 2rem;
    }
    .exit{
        margin-left: 10px;
        font-size: 0.9rem;
    }
    .exit:hover{
        color: #219FD5;
        cursor: pointer;
    }
</style>