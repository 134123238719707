<template>
    <div>
        管理员
    </div>
</template>

<script>
export default {
    
}
</script>

<style scoped>

</style>