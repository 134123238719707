<template>
    <div class="container">
        <el-table :data="orderIns" border style="width: 100%" height="78vh" class="tb-edit" highlight-current-row v-loading="loading">
            <el-table-column label="序号">
            <template slot-scope="scope">
              {{scope.$index+1}} 
            </template>
          </el-table-column>
            <el-table-column prop="confirmState" label="确认状态" width="80">
                <template slot-scope="scope">
                <el-tag :type="scope.row.confirmState == 0 ? 'danger' : 'success'"
                disable-transitions>{{scope.row.confirmState==0?"待确认":"已确认"}}</el-tag>
            </template>
            </el-table-column>
            <el-table-column prop="inCombinationCode" label="入库批次" width="180"></el-table-column>
            <el-table-column prop="productName" label="产品名称" width="180"></el-table-column>
            <el-table-column prop="unit" label="计价单位"></el-table-column>
            <el-table-column prop="price" label="单价"></el-table-column>
            <el-table-column prop="actualNum" label="入库数量" width="120"></el-table-column>
            <el-table-column prop="singleWeight" label="单重"></el-table-column>
            <el-table-column prop="actualWeight" label="入库重量" width="120"></el-table-column>
            <el-table-column prop="actualLength" label="入库长度" width="120"></el-table-column>
            <el-table-column prop="actualArea" label="入库面积" width="120"></el-table-column>
            <el-table-column prop="furnaceNum" label="包炉数" width="120"></el-table-column>
            <el-table-column prop="money" label="入库金额" width="120"></el-table-column>
            <el-table-column prop="lowCharge" label="最低收费"></el-table-column>
            <el-table-column prop="preDeliveryTime" label="预交货时间" width="180"></el-table-column>
        </el-table>
        <el-dialog title="添加产品" :visible.sync="addProductDialog" :close-on-click-modal="closeOnClickModal" width="80%">
            <el-button type="primary" @click="toChooseProduct()">选择待入库产品</el-button>
            <el-button type="primary" @click="saveProduct()">提交</el-button>
            <el-table :data="tableData" border style="width: 100%" class="tb-edit" highlight-current-row @row-click="handleCurrentChange3">
                <el-table-column prop="act" label="操作" width="80">
                    <template slot-scope="scope">
                        <el-button size="mini" type="danger" @click="handleDelete(scope.$index, scope.row)">删除</el-button>
                    </template>
                </el-table-column>
                <el-table-column prop="productName" label="产品名称" width="180"></el-table-column>
                <el-table-column prop="unit" label="计价单位"></el-table-column>
                <el-table-column prop="price" label="单价"></el-table-column>
                <el-table-column prop="actualNum" label="入库数量" width="120">
                    <template slot-scope="scope">
                        <el-input size="small" v-model="scope.row.actualNum" placeholder="请输入数量" @blur='addZero($event)' @focus='removeZero($event)' @input="handleEdit(scope.$index, scope.row,'actualNum')"></el-input><span>{{scope.row.actualNum}}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="singleWeight" label="单重"></el-table-column>
                <el-table-column prop="actualWeight" label="入库重量" width="120">
                    <template slot-scope="scope">
                        <el-input size="small" v-model="scope.row.actualWeight" placeholder="请输入重量" @blur='addZero($event)' @focus='removeZero($event)' @input="handleEdit(scope.$index, scope.row,'actualWeight')"></el-input><span>{{scope.row.actualWeight}}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="actualLength" label="入库长度" width="120">
                    <template slot-scope="scope">
                        <el-input size="small" v-model="scope.row.actualLength" placeholder="请输入长度" @blur='addZero($event)' @focus='removeZero($event)' @input="handleEdit(scope.$index, scope.row,'actualLength')"></el-input><span>{{scope.row.actualLength}}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="actualArea" label="入库面积" width="120">
                    <template slot-scope="scope">
                        <el-input size="small" v-model="scope.row.actualArea" placeholder="请输入面积" @blur='addZero($event)' @focus='removeZero($event)' @input="handleEdit(scope.$index, scope.row,'actualArea')"></el-input><span>{{scope.row.actualArea}}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="furnaceNum" label="包炉数" width="120">
                    <template slot-scope="scope">
                        <el-input size="small" v-model="scope.row.furnaceNum" placeholder="请输入包炉数" @input="handleEdit(scope.$index, scope.row,'furnaceNum')"></el-input><span>{{scope.row.furnaceNum}}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="money" label="入库金额" width="120">
                    <template slot-scope="scope">
                        <el-input size="small" v-model="scope.row.money" placeholder="请输入金额" @input="handleEdit(scope.$index, scope.row,'money')"></el-input><span>{{scope.row.money}}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="lowCharge" label="最低收费"></el-table-column>
                <el-table-column prop="preDeliveryTime" label="预交货时间" width="180">
                        <template slot-scope="scope">
                        <el-date-picker v-model="scope.row.preDeliveryTime" type="date" placeholder="选择日期" width='150'></el-date-picker><span>{{scope.row.preDeliveryTime}}</span>
                    </template>
                </el-table-column>
            </el-table>
        </el-dialog>
        <el-dialog title="待入库产品" :visible.sync="dialogTableVisible" :close-on-click-modal="closeOnClickModal">
            <el-button type="primary" icon="el-icon-goods" @click="mackSurePro()">确定</el-button>
            <el-table :data="productTab" border style="width: 100%" height="350" @selection-change="selectRow">
                <el-table-column type="selection" width="45"></el-table-column>
                <el-table-column prop="productName" label="产品名称" width="180" fixed></el-table-column>
                <el-table-column prop="hasTax" label="是否含税"></el-table-column>
                <el-table-column prop="unit" label="计价单位"></el-table-column>
                <el-table-column prop="price" label="单价"></el-table-column>
                <el-table-column prop="actualNum" label="入库数量"></el-table-column>
                <el-table-column prop="singleWeight" label="单重"></el-table-column>
                <el-table-column prop="actualWeight" label="入库重量"></el-table-column>
                <el-table-column prop="actualLength" label="入库长度"></el-table-column>
                <el-table-column prop="actualArea" label="入库面积"></el-table-column>
                <el-table-column prop="furnaceNum" label="包炉数"></el-table-column>
                <el-table-column prop="money" label="入库金额"></el-table-column>
                <el-table-column prop="lowCharge" label="最低收费"></el-table-column>
            </el-table>
            <div class='pagination'>
                    <el-pagination @current-change="handleCurrentChange" :current-page="page" :page-size="20" :page-sizes="pageSize" layout="total, sizes, prev, pager, next, jumper" :total="total"></el-pagination>
                </div>
        </el-dialog>
        <el-dialog title="入库保存" :visible.sync="dialogTableVisible3" width="80%" :close-on-click-modal="closeOnClickModal2">
            <el-form :inline="true" :model="form" class="demo-form-inline">
                <el-form-item label="客户名称">
                    <el-input v-model="form.name" placeholder="客户名称" disabled></el-input>
                </el-form-item>
                <el-form-item label="入库日期">
                    <el-date-picker v-model="form.orderInTime" align="right" type="date" value-format="yyyy-MM-dd" placeholder="选择日期" :picker-options="pickerOptions" :default-time="form.orderInTime"></el-date-picker>
                </el-form-item>
                <el-form-item label="入库时间">
                    <el-time-picker v-model="form.orderInDate" format="HH:mm" value-format="HH:mm" :picker-options="{ selectableRange: '00:00:00 - 23:59:59' }" placeholder="选择时间" :default-time="form.orderInDate"></el-time-picker>
                </el-form-item>
                <el-form-item label="经办时间">
                    <el-date-picker v-model="form.orderInByTime" type="datetime" value-format="yyyy-MM-dd HH:mm:ss" placeholder="选择日期时间" :default-time="form.orderInByTime"></el-date-picker>
                </el-form-item>
                <br>
                <el-form-item>
                    <el-button type="primary" @click="onSubmit">保存</el-button>
                    <el-input type='hidden'></el-input>
                </el-form-item>
            </el-form>
        </el-dialog>
    </div>
</template>

<script>
import qs from 'qs'
export default {
    data() {
        return {
            dialogTableVisible:false,
            dialogTableVisible3:false,
            addProductDialog:false,
            productTab:[],
            pageSize:[20],
            tableData:[],
            total:0,
            page:1,
            phone:this.$store.state.phone,
            selectedRow:[],
            closeOnClickModal:false,
            closeOnClickModal2:false,
            unitt:[],
            form: {
                name: '',
                code: '',
                orderInTime:"",
                orderInDate:'',
                orderInByTime:""
            },
            orderIns:[],
            loading:false,
            pickerOptions: {
                disabledDate(time) {
                    return time.getTime() > Date.now();
                },
                shortcuts: [{
                    text: '今天',
                    onClick(picker) {
                    picker.$emit('pick', new Date());
                    }
                }, {
                    text: '昨天',
                    onClick(picker) {
                    const date = new Date();
                    date.setTime(date.getTime() - 3600 * 1000 * 24);
                    picker.$emit('pick', date);
                    }
                }, {
                    text: '一周前',
                    onClick(picker) {
                    const date = new Date();
                    date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
                    picker.$emit('pick', date);
                    }
                }]
            }
        }
    },
    props:{
        clientName:{
            type:String,
            default:""
        },
        activeName:{
            type:String,
            default:""
        },
        clientIp:{
            type:String,
            default:""
        }
    },
    methods: {
        handleCurrentChange:function(){

        },
        toChooseProduct:function(){
            this.dialogTableVisible=true;
            this.addProduct()
        },
        addZero:function(e){
            let value = e.target.value;
            if(!value){
                e.target.value = 0;
            }
        },
        removeZero:function(e){
            let value = e.target.value;
            if(value==0){
                e.target.value = "";
            }
        },
        handleEdit:function(index, row,field){
            let unit = row.unit;
            let unit2="";
            let arr = this.unitt;
            let price = row.price?row.price:0;
            let singleWeight = row.singleWeight?row.singleWeight:0;
            let lowCharge = row.lowCharge?row.lowCharge:0;
            for (let i = 0; i < arr.length; i++) {
                if(unit==arr[i].unitName){
                    unit2 = arr[i].corresponding;
                    break;
                }
            }
            //数量
            if(field=="actualNum"){
                let weight = this.accMul(row.actualNum,singleWeight);
                this.tableData[index].actualWeight = weight;
                if(unit=='件' || unit2=="数量"){
                    let money = this.accMul(row.actualNum,price)>lowCharge?this.accMul(row.actualNum,price):lowCharge;
                    this.tableData[index].money = money;
                }else if(unit=='kg' || unit2=='重量'){
                    let money = this.accMul(weight,price)>lowCharge?this.accMul(weight,price):lowCharge;
                    this.tableData[index].money = Number(money).toFixed(2);
                }
            //重量
            }else if(field=="actualWeight"){
                if(unit=='kg' || unit2=='重量'){
                    let money = this.accMul(row.actualWeight,price)>lowCharge?this.accMul(row.actualWeight,price):lowCharge;
                    this.tableData[index].money = Number(money).toFixed(2);
                }
            //长度
            }else if(field=="actualLength"){
                if(unit=='米' || unit2=='长度'){
                    let money = this.accMul(row.actualLength,price)>lowCharge?this.accMul(row.actualLength,price):lowCharge;
                    this.tableData[index].money = Number(money).toFixed(2);
                }
            //面积
            }else if(field=="actualArea"){
                if(unit=='米' || unit2=='长度'){
                    let money = this.accMul(row.actualArea,price)>lowCharge?this.accMul(row.actualArea,price):lowCharge;
                    this.tableData[index].money = Number(money).toFixed(2);
                }
            //包炉数
            }else if(field=="furnaceNum"){
                if(unit=='炉'){
                    let money = this.accMul(row.furnaceNum,price)>lowCharge?this.accMul(row.furnaceNum,price):lowCharge;
                    this.tableData[index].money = Number(money).toFixed(2);
                }
            }
        },
        handleDelete:function(index, row){
            this.tableData.splice(index, 1)
        },
        selectRow:function(val){
            this.selectedRow = val;
        },
        handleCurrentChange3:function(row, event, column){
            console.log(row, event, column, event.currentTarget)
        },
        saveProduct:function(){
            if(this.tableData.length==0){
                this.$alert("请添加入库产品","商能科技提醒您");
                return false;
            }
            this.dialogTableVisible3 = true;
            this.getTime();
            this.form.name = this.tableData[0].name;
            this.form.code = this.tableData[0].code;
        },
        getOrderIns:function(){
            this.loading=true;
            this.$axios.post(
                this.clientIp+"/findOrderInByClientPhone.action",
                qs.stringify(
                    {
                        phone:this.phone,
                        inCombinationCode:this.$parent.searchForm2.inCombinationCode?this.$parent.searchForm2.inCombinationCode:"",
                        productName:this.$parent.searchForm2.productName?this.$parent.searchForm2.productName:"",
                    })
                ).then(res=>{
                // var arr = res.data;
                // for (let i = 0; i < arr.length; i++) {
                //     if(arr[i].confirmState==0){
                //         arr[i].confirmState="未确认"
                //     }else if(arr[i].confirmState==1){
                //         arr[i].confirmState="已确认"
                //     }
                    
                // }
                this.orderIns = res.data;
            }).catch(err=>{
                console.log(err);
            }).finally(()=>{
                this.loading=false;
            })
        },
        
        onSubmit() {
            this.tableData.forEach(item=>{
                item.inPrices=[{id:null,orderInId:null,unit:item.unit,price:item.price,proPriceNote:null}]
                item.prices = item.price
            })
            let tableData = JSON.parse(JSON.stringify(this.tableData))
            this.form.jsonOrderIns = JSON.stringify(tableData);
            this.$axios.post(this.clientIp+"/ajaxSaveOrderIns.action",qs.stringify(this.form)).then(res=>{
                if(res.data.ok){
                    this.$alert("新增入库成功","商能科技提醒您"); 
                    this.dialogTableVisible3 = false;
                    this.tableData=[];
                    this.addProductDialog=false;
                    this.getOrderIns();
                    this.$axios({
                        url:this.$store.state.ip+"/log/insertLog",
                        method:"post",
                        data:qs.stringify({
                            userName:this.phone,
                            content:"提交了订单",
                            ip:returnCitySN["cip"]
                        })
                    })
                }else{
                    this.$alert("新增入库失败","商能科技提醒您");
                }
            }).catch(err=>{
                console.log("保存错误");
            })
        },
        getTime:function(){
            //获取当前时间
            var now = new Date();
            //获取年份
            var year = now.getFullYear(); 
            //获取月份
            var month = now.getMonth()+1;
            if(month<10){
                month='0'+month;
            }
            //获取当前天
            var day = now.getDate();
            //获取当前小时
            var hour = now.getHours();
            //获取当前分钟
            var min = now.getMinutes();
            if(min<10){
                min='0'+min;
            }
            //获取当前秒
            var second = now.getSeconds();
            if(second<10){
                second='0'+second;
            }
            //整合
            var time = year+'-'+month+'-'+day+' '+hour+':'+min+':'+second
            this.form.orderInByTime=time;
            this.form.orderInTime = year+'-'+month+'-'+day;
            this.form.orderInDate = hour+":"+min;
        },
        mackSurePro:function(){
          if(this.selectedRow.length==0){
              this.$alert("请勾选产品","商能科技提醒您");
              return false;
          }else{
                let arr = this.selectedRow;
                let temp = [];
                for (let i = 0; i < arr.length; i++) {
                    temp.push(arr[i].productCode);
                }
                this.$axios.post(this.clientIp+"/getProByClientPhone.action",qs.stringify({jsonProduct:temp.join(","),phone:this.phone}))
                .then(res=>{
                    if(res){
                        let arr = res.data.orderIns;   
                        for (let i = 0; i < arr.length; i++) {
                            this.tableData.push(arr[i]);
                        }
                        this.dialogTableVisible=false;
                    }else{
                        this.$alert("选择产品出错1","商能科技提醒您");
                        return false;
                    }
                }).catch(err=>{
                    this.$alert("选择产品出错2","商能科技提醒您");
                    return false;
                })
            }
        },
        addProduct:function(){
            this.$axios.post(this.clientIp+"/ajaxShowProListByCilentPhone.action",qs.stringify({phone:this.phone})).then(res=>{
                this.productTab=[];
                this.dialogTableVisible = true;
                this.page = res.data.page?Number(res.data.page):1;
                this.total = res.data.total;
                this.totalPage = res.data.totalPage;
                //this.productTab = res.rows;
                let arr = res.data.rows;
                for (let i = 0; i < arr.length; i++) {
                    if(arr[i].hasTax==0){
                        arr[i].hasTax="不含税"
                    }else if(arr[i].hasTax==1){
                        arr[i].hasTax="含税"
                    }
                    this.productTab.push(arr[i])
                }
            }).catch(err=>{
                this.$alert("选择产品出错","商能科技提醒您");
                return false;
            })
        },
        //倆数相乘-精确
        accMul:function(arg1,arg2){ 
            var m=0,s1=arg1.toString(),s2=arg2.toString(); 
            try{m+=s1.split(".")[1].length}catch(e){} 
            try{m+=s2.split(".")[1].length}catch(e){} 
            return Number(s1.replace(".",""))*Number(s2.replace(".",""))/Math.pow(10,m) 
        },
        //倆数相加-精确
        accAdd:function(arg1,arg2){
            var r1,r2,m;
            try{r1=arg1.toString().split(".")[1].length}catch(e){r1=0}
            try{r2=arg2.toString().split(".")[1].length}catch(e){r2=0}
            m=Math.pow(10,Math.max(r1,r2));
            return (accMul(arg1,m)+accMul(arg2,m))/m; 
        },
        getUnit:function(){
            this.$axios.post(this.clientIp+"/findAllUnit.action").then(res=>{
                this.unitt=res.data;
            }).catch(err=>{
                console.log("获取单位错误");
            })
        }
    },
    watch: {
        clientName(val){
            if(val && this.activeName=="second"){
                this.getUnit();
            }
        },
        activeName(val){
            if(this.clientName && val=="second"){
                this.getUnit();
            }
        },
        clientIp(val){
            if(val && this.activeName =="second"){
                this.getUnit();
            }
        }
    },
    mounted() {

      
        this.getOrderIns();
    },
}
</script>

<style scoped>
    .tb-edit .el-input {
        display: none
    }
    .tb-edit .current-row .el-input {
        display: block
    }
    .tb-edit .current-row .el-input+span {
        display: none
    }
</style>