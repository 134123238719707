import Vue from "vue"
import Vuex from "vuex"
import {setStorage,getStorage} from "@/views/newLocalStorage/index.js"

Vue.use(Vuex)

const state = {
    ip:"http://www.jgzx.cn/jgzx",
    // ip:"http://192.168.0.12:8080",
    //fileIp: 'http://36.152.120.148/erp',
    token:getStorage("userToken")?getStorage("userToken"):"",
    phone:getStorage("userPhone")?getStorage("userPhone"):"",
    userMes:getStorage("userUserMes")?getStorage("userUserMes"):"",
}

const mutations = {
    setToken(state,val){
        return state.token = val;
    },
    setPhone(state,val){
        return state.phone = val;
    },
    setUserMes(state,val){
        return state.userMes = val;
    }
}
const actions = {
    
}
const getters = {

}

export default new Vuex.Store({
    state,
    mutations,
    actions,
    getters
})