<template>
    <div class="container">
        <!-- <el-button style="position: fixed; top:-50;z-index: 100;" type="primary"  @click="exportExecl()">导出<i
                class="el-icon-download el-icon--right" ></i></el-button> -->
        <el-table class="table-auto-width" :data="tableData" border style="width:100%;word-wrap: break-word;word-break: break-all;" height="72vh" :cell-style="bgc" fit v-loading="loading">
            <el-table-column v-for="item in tableDataColumn" :key="item.id" :prop="item.englishField"
                :label="item.oldName" min-width="160">
                <template #default="scope">
                    <div> {{ scope.row[item.englishField] }}</div>
                </template>
            </el-table-column>

        </el-table>
        <div class='pagination'>
            <el-pagination @current-change="handleCurrentChange" :current-page="page" :page-size="20"
                :page-sizes="pageSize" layout="total, sizes, prev, pager, next, jumper" :total="total"></el-pagination>
        </div>
       
    </div>
</template>

<script>
import qs from 'qs'
export default {
    data() {
        return {
            tableData: [],
            phone: this.$store.state.phone,
            pageSize: [20],
            total: 0,
            page: 1,
            loading: false,
            tableDataColumn: []
        }
    },
    props: {
        clientName: {
            type: String,
            default: ""
        },
        activeIndex: {
            type: String,
            default: ""
        },
        clientIp: {
            type: String,
            default: ""
        }
    },
    methods: {
        exportExecl() {
            if(this.clientIp==''){
                return alert("请先选择供应商！")
            }
            let inCombinationCode = this.$parent.searchForm1.inCombinationCode ? this.$parent.searchForm1.inCombinationCode : "";
            let productName = this.$parent.searchForm1.productName ? this.$parent.searchForm1.productName : "";
            let outState = this.$parent.searchForm1.status ? this.$parent.searchForm1.status : "";

            window.open(this.clientIp + "/exportOrderIn.action?inCombinationCode=" + inCombinationCode + "&productName=" + productName + "&outState=" + outState+ "&phone=" + this.phone)
        },
        handleCurrentChange: function (val) {
            this.page = val;
            this.showPageData()
        },
        bgc: function (row) {
            // if(row.column.)
            var field = row.column.property;
            var r = row.row;
            if (r[field] == "未编" || r[field] == "未检" || r[field] == "未排" || r[field] == "未生产" || r[field] == "未出" || r[field] == "未出完" || r[field] == "已对账" || r[field] == "填写") {
                return "background:red;"
            } else if (r[field] == "已入" || r[field] == "合格" || r[field] == "已编" || r[field] == "已排" || r[field] == "完工" || r[field] == "已出" || r[field] == "入库已结" || r[field] == "出库已结") {
                return "background:#92D050";
            } else if (r[field] == "生产中" || r[field] == "在排") {
                return "background:#00B0F0";
            } else if (r[field] == "不合格") {
                return "background:orange";
            }
        },
        showPageData: function () {
            this.loading = true;
            this.$axios.post( this.clientIp +"/ajaxGetProductStatesByClientPhone.action", qs.stringify(
                {
                    phone: this.phone,
                    page: this.page,
                    inCombinationCode: this.$parent.searchForm1.inCombinationCode ? this.$parent.searchForm1.inCombinationCode : "",
                    productName: this.$parent.searchForm1.productName ? this.$parent.searchForm1.productName : "",
                    outState: this.$parent.searchForm1.status ? this.$parent.searchForm1.status : ""
                }
            )).then(res => {
                let arr = res.data.rows;
                this.totalPage = res.data.totalPage;
                this.total = res.data.total;
                this.page = res.data.page ? Number(res.data.page) : 1;
                this.tableData = [];
                for (let i = 0; i < arr.length; i++) {
                    arr[i].orderInState = "已入";
                    // IQC检验
                    if (arr[i].iqcCheckState != 2 && arr[i].iqcCheckState != 3) {
                        arr[i].iqcState = "未检";
                    } else if (arr[i].iqcCheckState == 2) {
                        arr[i].iqcState = "合格";
                    } else if (arr[i].iqcCheckState == 3) {
                        arr[i].iqcState = "不合格";
                    }

                    // 工艺
                    if (arr[i].craftId != null || arr[i].craftId != "") {
                        arr[i].craftState = "已编";
                    } else {
                        arr[i].craftState = "未编";
                    }
                    //排产状态
                    if (arr[i].schedulingState == -1) {
                        arr[i].schedulingState = "未排";
                    } else if (arr[i].schedulingState == 0) {
                        arr[i].schedulingState = "在排";
                    } else if (arr[i].schedulingState == 1) {
                        arr[i].schedulingState = "已排";
                    }
                    //生产过程
                    if (arr[i].processState == 0) {
                        arr[i].produceProcess = "未生产";
                    } else if (arr[i].processState == 2) {
                        arr[i].produceProcess = "生产中";
                    } else {
                        arr[i].produceProcess = "完工";
                    }
                    //终检状态
                    if (arr[i].finalCheckState == 0) {
                        arr[i].finalCheckState = "未检";
                    } else if (arr[i].finalCheckState == 1) {
                        arr[i].finalCheckState = "合格";
                    } else {
                        arr[i].finalCheckState = "不合格";
                    }
                    //出库状态
                    if (arr[i].outState == 0) {
                        arr[i].outState = "未出";
                    } else if (arr[i].outState == 1) {
                        arr[i].outState = "未出完";
                    } else if (arr[i].outState == 2) {
                        arr[i].outState = "已出";
                    }

                    //结算状态
                    if (arr[i].paymentState == 0) {
                        arr[i].paymentState = "已对账";
                    } else if (arr[i].paymentState == 1) {
                        arr[i].paymentState = "填写";
                    } else if (arr[i].paymentState == 2) {
                        arr[i].paymentState = "入库已结";
                    } else if (arr[i].paymentState == 3) {
                        arr[i].paymentState = "出库已结";
                    }
                    this.tableData.push(arr[i])
                }
            }).catch(err => {
                console.log(err);
            }).finally(() => {
                this.loading = false;
            })
        },
        getColumn: function () {
            this.loading = true;
            this.$axios.post(
                // 
                this.clientIp +
                "/findClientProcessing.action",
                qs.stringify(
                    {
                        phone: this.phone,
                    })
            ).then(res => {

                this.tableDataColumn = res.data
            }).catch(err => {
                console.log(err);
            }).finally(() => {
                this.loading = false;
            })
        },
    },
    watch: {
        clientName(val) {
            if (val && this.activeName == "first") {
                this.tableData = [];
                this.showPageData();
                this.getColumn()
            }
        },
        clientIp(val) {
            this.tableData = [];
            this.showPageData();
            this.getColumn()
        }
    },
    mounted() {
        this.getColumn()
        if (this.clientIp) {
            this.tableData = [];
            this.showPageData();
            this.getColumn()
        }
    },
}
</script>

<style scoped>
* {
    margin: 0;
    padding: 0;
}

.autoHeight {
    position: relative;
    top: 0;
    bottom: 0;
    overflow: hidden;
}

.container {
    width: 100%;
    height: 100%;
    overflow-y: hidden;
}
.table-auto-width .el-table__body td {
  word-break: break-all;
}
 
/* 设置表格列宽为自适应 */
.table-auto-width .el-table__body td,
.table-auto-width .el-table th {
  width: auto;
  min-width: 150px;
}
</style>