<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
import {setStorage,getStorage} from '@/views/newLocalStorage/index.js'
  export default {
    name: 'App',
  }
</script>

<style>
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
*{
    margin: 0;
    padding: 0;
}
body{
  margin: 0;
  padding: 0;
  overflow-x: auto; 
  white-space: nowrap;
  /* background-image: linear-gradient(to right,#D6E6F7,#ACCBF0,#D6E6F7) */
}
/* ::-webkit-scrollbar {
     width: 0 !important;
   }
   ::-webkit-scrollbar {
     width: 0 !important;height: 0;
   } */
</style>
