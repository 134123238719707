<template>
    <div class="container">
        <div class="leftbox" ></div>
        <div class="rightbox">
            <el-form ref="form" :model="form" label-width="80px" class="login-form">
                <h2 class="login-title">加工在线后台管理系统</h2>
                <el-form-item label="用户名">
                    <el-input v-model="form.account" placeholder="请输入账号"></el-input>
                </el-form-item>
                <el-form-item label="密码">
                    <el-input v-model="form.password" placeholder="请输入密码" show-password></el-input>
                </el-form-item>
                <el-form-item class="login-item">
                    <el-button type="primary" @click="onSubmit">登录</el-button>
                </el-form-item>
            </el-form>
        </div>
        <div class="topbox">
            <img src="@/assets/image/loginLogo.png" alt="">
            <span>加工在线</span>
        </div>
        <div class="bottombox" @click="toCompany">
            <p>南京商能科技有限公司</p>
            <p>www.shangneng.com.cn</p>
        </div>
    </div>
</template>

<script>
import qs from "qs"
import {setStorage,getStorage} from '@/views/newLocalStorage/index.js'
export default {
    data() {
        return {
            form:{
                account:"",
                password:""
            },
        }
    },
    methods: {
        toCompany:function(){
            window.open("http://www.shangneng.com.cn", '_blank');
        },
        //登录
        onSubmit:function(){
            this.$axios.post(
                this.$store.state.ip+"/adminUser/adminLogin",
                qs.stringify(this.form),
            ).then(res=>{
                if(res.data.check==true){
                    var date = new Date().getTime();
                    setStorage("jgzxAdmin",{
                        name:res.data.userName,
                        id:res.data.userId
                    },date+7200000);//有效期2小时
                    this.$router.push("/admin")
                }else{
                    alert("验证错误");
                    return false;
                }
            }).catch(err=>{
                alert("服务端错误，请稍后重试");
                console.log("验证出现错误",err)
            })
        },
    },
}
</script>

<style scoped>
    .container{
        width: 100%;
        height: 100%;
        position: absolute;
        background: url("~@/assets/image/bg.jpg") no-repeat;
        background-size:100% 100%;
        background-attachment:fixed;
    }
    .container>div{
        float: left;
    }
    .leftbox{
        width: 50%;
        height: 500px;
        background: transparent;
    }
    .rightbox{
        width: 50%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .topbox{
        position: fixed;
        top: 0;
        width: 100%;
        height: 6rem;
        background-color: cornflowerblue;
        opacity: 0.4;
    }
    .topbox>img{
        float: left;
        width: 5rem;
        height: 5rem;
        margin-top: 0.5rem;
        margin-left: 8rem;
    }
    .topbox>span{
        float:left;
        height: 5rem;
        color: #000;
        margin-top: 0.5rem;
        padding-left: 2rem;
        font-size: 2rem;
        line-height: 5rem;
        display: inline-block;
    }
    .bottombox{
        position: fixed;
        bottom: 0;
        width: 100%;
        height: 8rem;
        color: #fff;
        background-image: linear-gradient(to right,#2C55A5,teal,#2D5DA9);
        opacity: 0.9;
        cursor: pointer;
    }
    .bottombox>p:first-child{
        font-size: 2rem;
        text-align: center;
        margin-top: 2rem;
    }
    .bottombox>p:last-child{
        text-align: center;
        margin-top: 0.5rem;
    }
    .login-form{
        width: 360px;
        background-color: rgb(255, 255, 255, 0.8);
        padding: 30px;
        border-radius: 20px;
    }
    .login-title {
        color: #303133;
        text-align: center;
        margin-bottom: 20px;
    }
    .more>>>.el-button{
        text-align: left;
    }
    .login-form>>>.login-item{
        text-align: center;
    }
    .autoHeight{
        position: relative;
        top:0;
        bottom: 0;
        overflow: hidden;
    }
</style>