<template>
    <div class="container">
        <div class="searchBar">
            <el-input placeholder="用户名" v-model="search.clientName" clearable style="width:200px;" @input="getData" @clear="getData"></el-input>
            <el-input placeholder="公司名称" v-model="search.companyName" clearable style="width:200px;" @input="getData" @clear="getData"></el-input>
            <el-button type="primary" icon="el-icon-search" @click="getData">搜索</el-button>
        </div>
        <el-table :data="tableData" border style="width: 100%" height="82.5vh">
            <el-table-column prop="companyName" label="公司名称" header-align="center" width="300"></el-table-column>
            <el-table-column prop="clientNames" label="供应商" header-align="center"></el-table-column>
        </el-table>
        <div class="footer">
            <el-pagination background layout="prev, pager, next" :total="total" @prev-click="changePage" @next-click="changePage"></el-pagination>   
        </div>
    </div>
</template>

<script>
import qs from 'qs'
export default {
    data() {
        return {
            search:{
                clientName:"",
                companyName:"",
                index:1
            },
            total:1,
            tableData: [],
        }
    },
    methods: {
        changePage:function(page){
            this.search.index = page;
            this.getData();
        },
        getData:function(){
            this.$axios({
                url:this.$store.state.ip+"/adminUser/showAllCompany",
                method:"post",
                data:qs.stringify(this.search)
            }).then(res=>{
                this.tableData = res.data.rows;
                this.total = res.data.totalPage;
            }).catch(err=>{
                console.log(err);
            })
        },
    },
    mounted() {
        this.getData();
    },
}
</script>

<style scoped>
    .container{
        width: 100%;
        height: 92vh;
    }
    .searchBar{
        margin-top: 3px;
        height: 5vh;
        margin-bottom: 2px;
    }
    .footer{
        text-align: center;
    }
</style>