<template>
    <div>
       <el-table :data="tableData" border style="width: 100%" height="78vh" v-loading="loading">
           <el-table-column prop="moneyCheckCode" label="对账单编号" fixed></el-table-column>
           <el-table-column prop="moneyCheckMonth" label="月份"></el-table-column>
           <el-table-column prop="orderOutMoney" label="出库金额"></el-table-column>
           <el-table-column prop="moneyCheckMoney" label="对账金额"></el-table-column>
           <el-table-column prop="billingMoney" label="开票金额"></el-table-column>
           <el-table-column prop="returnMoneyMoney" label="回款金额"></el-table-column>
           <el-table-column prop="withholdMoney" label="扣款金额"></el-table-column>
           <el-table-column prop="indemnityMoney" label="赔偿金额"></el-table-column>
           <el-table-column prop="otherMoney" label="其他金额"></el-table-column>
           <el-table-column prop="lastMoney" label="最终确认金额合计"></el-table-column>
           <el-table-column prop="preReturnMoneyTime" label="预计回款时间"></el-table-column>
           <el-table-column prop="createTime" label="对账时间"></el-table-column>
       </el-table>
       <div class='pagination'>
            <el-pagination @current-change="handleCurrentChange" :current-page="page" :page-size="20" :page-sizes="pageSize" layout="total, sizes, prev, pager, next, jumper" :total="total"></el-pagination>
        </div>
    </div>
</template>

<script>
import qs from 'qs'
export default {
    data() {
        return {
            loading:false,
            tableData:[],
            phone:this.$store.state.phone,
            pageSize:[20],
            total:0,
            page:1,
        }
    },
    props:{
        clientName:{
            type:String,
            default:""
        },
        activeName:{
            type:String,
            default:""
        },
        clientIp:{
            type:String,
            default:""
        },
    },
    methods: {
        handleCurrentChange:function(val) {
            this.page=val;
            this.showPageData()
        },
        showPageData:function(){
            this.loading=true;
            this.$axios.post(this.clientIp+"/ajaxShowMoneyCheckPubsByClientPhone.action",qs.stringify(
                {
                    phone:this.phone,
                    page:this.page,
                    moneyCheckMonth:this.$parent.searchForm3.moneyCheckMonth?this.$parent.searchForm3.moneyCheckMonth:"",
                    moneyCheckCode:this.$parent.searchForm3.moneyCheckCode?this.$parent.searchForm3.moneyCheckCode:"",
                }
            )).then(res=>{
                this.totalPage = res.data.totalPage;
                this.total = res.data.total;
                this.page = res.data.page?Number(res.data.page):1;
                this.tableData=res.data.rows;
            }).catch(err=>{
                console.log(err);
            }).finally(()=>{
                this.loading=false;
            })
        },
    },
    mounted() {
        if(this.clientIp){
            this.tableData=[];
            this.showPageData();
        }
    },
}
</script>

<style scoped>

</style>