<template>
    <div>
        <el-table :data="backData" border style="width: 100%" height="78vh" v-loading="loading">
            <el-table-column prop="feedBackCode" label="问题编号" width="130"></el-table-column>
            <el-table-column prop="name" label="产品名称" width="180"></el-table-column>
            <el-table-column prop="outCombinationCode" label="出库批次" width="180"></el-table-column>
            <el-table-column prop="feedBackMessage" label="反馈内容"></el-table-column>
            <el-table-column prop="feedBackState" label="答复状态" width="90"></el-table-column>
        </el-table>
        <el-dialog title="提交问题反馈" :visible.sync="addQuestionDialog" :close-on-click-modal="closeOnClickModal" width="75%" top="8vh">
            <el-form ref="suggest" :model="suggest" label-width="80px" class="suggest">
                <el-form-item>
                    <el-col :span="2" style='text-align:center'>
                        <label>客户名称</label>
                    </el-col>
                    <el-col :span="10">
                        <el-input v-model="suggest.name" placeholder="客户名称" disabled></el-input>
                    </el-col>
                    <el-col :span="2" style='text-align:center'>
                        <label>产品名称</label>
                    </el-col>
                    <el-col :span="6">
                        <el-input v-model="suggest.productName" placeholder="产品名称" @click.native="chooseProduct()" readonly></el-input>
                    </el-col>
                    <el-col :span="2">
                        <el-button type="primary" @click="removeProduct()">清空</el-button>
                    </el-col>
                </el-form-item>
                <el-form-item>
                    <el-col :span="2" style='text-align:center'>
                        <label>产品编号</label>
                    </el-col>
                    <el-col :span="10">
                        <el-input v-model="suggest.productCode" placeholder="产品编号" ></el-input>
                    </el-col>
                    <el-col :span="2" style='text-align:center'>
                        <label>出库批次</label>
                    </el-col>
                    <el-col :span="6">
                        <el-input v-model="suggest.outCombinationCode" placeholder="出库批次"></el-input>
                    </el-col>
                </el-form-item>
                <el-form-item>
                    <el-col :span="2" style='text-align:center'>
                        <label>反馈日期</label>
                    </el-col>
                    <el-col :span="10">
                        <el-date-picker v-model="suggest.feedBackDate" format="yyyy-MM-dd" :default-time="suggest.feedBackDate" align="right" type="date" placeholder="反馈日期"></el-date-picker>
                    </el-col>
                        <el-col :span="2" style='text-align:center'>
                        <label>答复日期</label>
                    </el-col>
                    <el-col :span="10">
                        <el-date-picker v-model="suggest.responseDay" format="yyyy-MM-dd" align="right" type="date" placeholder="答复日期"></el-date-picker>
                    </el-col>
                </el-form-item>
                <el-form-item>
                    <el-col :span="2" style='text-align:center'>
                        <label>反馈内容</label>
                    </el-col>
                    <el-col :span="12">
                        <el-input type="textarea" :autosize="{ minRows: 6, maxRows: 10}" placeholder="请输入反馈内容" maxlength="500" show-word-limit v-model="suggest.feedBackMessage"></el-input>
                    </el-col>
                </el-form-item>
                <el-form-item>
                    <el-col :span="2" style='text-align:center'>
                        <label>记录人</label>
                    </el-col>
                    <el-col :span="10">
                        <el-input v-model="suggest.recordMan" placeholder="记录人" readonly></el-input>
                    </el-col>
                    <el-col :span="2" style='text-align:center'>
                        <label>记录日期</label>
                    </el-col>
                    <el-col :span="10">
                        <el-date-picker v-model="form.recordTime" type="datetime" value-format="yyyy-MM-dd HH:mm:ss" placeholder="记录日期" :default-time="form.recordTime"></el-date-picker>
                    </el-col>
                </el-form-item>
                <el-form-item>
                    <el-col :span="2" style='text-align:center'>
                        <label>制单人</label>
                    </el-col>
                    <el-col :span="10">
                        <el-input v-model="suggest.originator" placeholder="制单人" readonly></el-input>
                    </el-col>
                    <el-col :span="2" style='text-align:center'>
                        <label>制单日期</label>
                    </el-col>
                    <el-col :span="10">
                        <el-date-picker v-model="suggest.origTime" format="yyyy-MM-dd" align="right" type="date" placeholder="制单日期"></el-date-picker>
                    </el-col>
                </el-form-item>
                <el-form-item style="text-align:center;">
                    <el-button type="primary" @click="suggestFun()">提交</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
        <el-dialog title="选择批次" :visible.sync="dialogTableVisible" width="40%" top="40px">
            <el-button type="primary" @click="toChoosePro">选择</el-button>
            <el-table :data="tableData" border height='500' @selection-change="toChoose1">
                    <el-table-column type="selection"  style="width:4%;"></el-table-column>
                    <el-table-column prop="outCombinationCode" label="出库批次" style="width:48%;" fixed></el-table-column>
                    <el-table-column prop="name" label="产品名称" style="width:48%;"></el-table-column>
                </el-table>
                <!-- <div class='pagination'>
                    <el-pagination @current-change="handleCurrentChange" :current-page="page" :page-size="20" :page-sizes="pageSize" layout="total, sizes, prev, pager, next, jumper" :total="total"></el-pagination>
                </div> -->
        </el-dialog>
    </div>
</template>

<script>
import qs from 'qs'
export default {
    data() {
        return {
            phone:this.$store.state.phone,
            suggest:{
                name:"",//客户名称
                feedBackDate:"",//反馈日期
                productName:"",//产品名称
                productCode:"",//产品编号
                outCombinationCode:"",//出库批次
                feedBackMessage:"",//反馈内容
                responseDay:"",//答复日期
                departmentName:"",//投诉部门
                recordMan:this.$store.state.phone,//经办人
                recordTime:"",//记录日期,
                originator:this.$store.state.phone,//制单人
                origTime:"",//制单日期
            },
            loading:false,
            dialogTableVisible:false,
            addQuestionDialog:false,
            closeOnClickModal:false,
            choosePro:[],
            tableData:[],
            backData:[],
            pageSize:[20],
            total:0,
            page:1,
            form: {
                name: '',
                code: '',
                orderInTime:"",
                orderInDate:'',
                orderInByTime:""
            },
        }
    },
    props:{
        clientName:{
            type:String,
            default:""
        },
        activeName:{
            type:String,
            default:""
        },
        clientIp:{
            type:String,
            default:""
        }
    },
    methods: {
        handleCurrentChange(val) {
            this.page=val;
            this.showPageData()
        },
        chooseProduct:function(){
            this.showPageData();
            this.getTime();
            this.dialogTableVisible = true;
        },
        removeProduct:function(){
            this.suggest.productName="";
            this.suggest.productCode="";
            this.suggest.outCombinationCode="";
        },
        toChoose1:function(val){
            this.choosePro = val
        },
        toChoosePro:function(){
            let productName = [];
            let productCode = [];
            let outCombinationCode = [];
            let val = this.choosePro;
            for (let i = 0; i < val.length; i++) {
                productName.push(val[i].productName);
                productCode.push(val[i].productCode);
                outCombinationCode.push(val[i].outCombinationCode);
            }
            this.suggest.name = val[0].name;
            this.suggest.productName = productName.join(",");
            this.suggest.productCode = productCode.join(",");
            this.suggest.outCombinationCode = outCombinationCode.join(",");
            this.dialogTableVisible = false;
        },
        getTime:function(){
            //获取当前时间
            var now = new Date();
            //获取年份
            var year = now.getFullYear(); 
            //获取月份
            var month = now.getMonth()+1;
            if(month<10){
                month='0'+month;
            }
            //获取当前天
            var day = now.getDate();
            //获取当前小时
            var hour = now.getHours();
            //获取当前分钟
            var min = now.getMinutes();
            if(min<10){
                min='0'+min;
            }
            //获取当前秒
            var second = now.getSeconds();
            if(second<10){
                second='0'+second;
            }
            //整合
            var time = year+'-'+month+'-'+day+' '+hour+':'+min+':'+second
            this.suggest.feedBackDate = year+'-'+month+'-'+day;
            this.suggest.recordTime = time;
            this.suggest.responseDay = year+"-"+month+"-"+day;
            this.suggest.origTime = year+'-'+month+'-'+day;
        },
        suggestFun:function(){
            debugger;
            if(!this.suggest.productName){
                this.$alert("请选择产品","商能科技提醒您");
                return false;
            }
            if(!this.suggest.feedBackMessage){
                this.$alert("请填写反馈内容","商能科技提醒您");
                return false;
            }
            this.$axios.post(this.clientIp+"/addClientFeedBack.action",qs.stringify(this.suggest)).then(res=>{
                if(res.data){
                    this.$axios({
                        url:this.$store.state.ip+"/log/insertLog",
                        method:"post",
                        data:qs.stringify({
                            userName:this.phone,
                            content:"提交了问题反馈",
                            ip:returnCitySN["cip"]
                        })
                    })
                    alert('提交反馈成功');
                    this.tableData=[];
                    this.suggest.name="";
                    this.suggest.feedBackDate="";
                    this.suggest.productName="";
                    this.suggest.productCode="";
                    this.suggest.outCombinationCode="";
                    this.suggest.feedBackMessage="";
                    this.suggest.responseDay="";
                    this.suggest.departmentName="";
                    this.suggest.recordMan="";
                    this.suggest.recordTime="";
                    this.suggest.originator="";
                    this.suggest.origTime="";
                    this.addQuestionDialog=false;
                    this.getData();
                }
            }).catch(err=>{

            })
        },
        showPageData:function(){
            this.$axios.post(this.clientIp+"/findAllOutCombinationCodeByPhone.action",qs.stringify({phone:this.phone,page:this.page})).then(res=>{
                this.tableData=res.data;
            })
        },
        getData:function(){
            this.loading=true;
            this.$axios.post(
                this.clientIp+"/ajaxGetAllClientFeedBacks.action",
                qs.stringify(
                    {
                        recordMan:this.phone,
                        page:1,
                        outCombinationCode:this.$parent.searchForm4.outCombinationCode?this.$parent.searchForm4.outCombinationCode:"",
                        feedBackMessage:this.$parent.searchForm4.feedBackMessage?this.$parent.searchForm4.feedBackMessage:"",
                    }
                )
            ).then(res=>{
                this.backData = res.data.rows;
            }).finally(()=>{
                this.loading=false;
            })
        }
    },
    mounted() {
        this.getData();
    },
}
</script>

<style scoped>
    .suggest{
        padding-top: 20px;
    }
</style>