// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import App from './App'
import router from './router'
import axios from "axios"
import qs from 'qs'
import Vuex from 'vuex'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import store from "@/store/index.js"
import storage from "@/views/newLocalStorage/index.js"
// import AFTableColumn from 'af-table-column'

Vue.config.productionTip = false
Vue.prototype.$axios = axios;
Vue.prototype.qs = qs;

Vue.use(ElementUI)
Vue.use(Vuex)
// Vue.use(AFTableColumn)

/* eslint-disable no-new */
// new Vue({
//   el: '#app',
//   router,
//   axios,
//   store,
//   qs,
//   components: { App },
//   template: '<App/>'
// })

new Vue({
  router,
  store,
  axios,
  qs,
  render: h => h(App)
}).$mount('#app')
