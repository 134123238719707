import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store/index'
import {setStorage,getStorage} from '@/views/newLocalStorage/index.js'


import Login from '@/views/login/login.vue'//登录页面
import Progress from "@/views/progress/index.vue"//热处理进度查询主页面
import SearchProgress from "@/views/progress/search_progress.vue"//进度查询页面
import OrderSubmit from "@/views/progress/order_submit.vue"//订单提交页面
import AccountInfo from "@/views/progress/account_info.vue"//账款信息页面
import Question from "@/views/progress/question.vue"//问题反馈页面

import homePage from "@/views/index.vue"


import adminLogin from "@/admin/login.vue"//后台管理登录页面
import adminIndex from "@/admin/index.vue"//后台管理主页面

import pageIndex from "@/admin/page/index.vue"//后台管理首页
import clientInfo from "@/admin/page/clientInfo.vue"//后台管理人员信息
import companyInfo from "@/admin/page/companyInfo.vue"//后台管理公司信息
import supplierInfo from "@/admin/page/supplierInfo.vue"//后台管理供应商
import suggestInfo from "@/admin/page/suggest.vue"//后台管理意见反馈
import logInfo from "@/admin/page/log.vue"//后台管理日志信息
import adminInfo from "@/admin/page/admin.vue"//后台管理管理员

Vue.use(Router)

// const originalPush = Router.prototype.push
// Router.prototype.push = function push(location) {
// 	   return originalPush.call(this, location).catch(err => err)
// 	}


const router = new Router({
  // mode:'history',
  base:"/",
  routes: [
    {
      path: '/login',
      name: 'login',
      component: Login
    },
    {
      path:"/",
      name:"homepage",
      component:homePage,
      meta:{
        isLogin:true
      }
    },
    {
      path:"/adminLogin",
      name:"adminLogin",
      component:()=>import("@/admin/login.vue")
    },
    {
      path:"/admin",
      name:"admin",
      component:()=>import("@/admin/index.vue"),
      redirect:"/pageIndex",
      meta:{
        isLoginAdmin:true
      },
      children:[
        {
          path:"/pageIndex",
          name:"pageIndex",
          component:pageIndex  
        },
        {
          path:"/clientInfo",
          name:"clientInfo",
          component:clientInfo
        },
        {
          path:"/companyInfo",
          name:"companyInfo",
          component:companyInfo
        },
        {
          path:"/supplierInfo",
          name:"supplierInfo",
          component:supplierInfo
        },
        {
          path:"/suggestInfo",
          name:"suggestInfo",
          component:suggestInfo
        },
        {
          path:"/logInfo",
          name:"logInfo",
          component:logInfo
        },
        {
          path:"/adminInfo",
          name:"adminInfo",
          component:adminInfo
        },
      ]
    },
    {
      path:"/progress",
      name:"progress",
      component:Progress,
      meta:{
        isLogin:true
      }
    },
    {
      path:"/searchProgress",
      name:"searchProgress",
      component:SearchProgress,
      meta:{
        isLogin:true
      }
    },
    {
      path:"/orderSubmit",
      name:"orderSubmit",
      component:OrderSubmit,
      meta:{
        isLogin:true
      }
    },
    {
      path:"/accountInfo",
      name:"accountInfo",
      component:AccountInfo,
      meta:{
        isLogin:true
      }
    },
    {
      path:"/question",
      name:"question",
      component:Question,
      meta:{
        isLogin:true
      }
    },
  ]
})

router.beforeEach((to,from,next)=>{
  if(to.matched.some(res=>res.meta.isLogin)){
    if(!getStorage("userPhone")){
      next({
        path:"/login"
      })
    }else{
      next()
    }
  }
  else if(to.matched.some(res=>res.meta.isLoginAdmin)){
    if(!getStorage("jgzxAdmin")){
      next({
        path:"/adminLogin"
      })
    }else{
      next();
    }
  }
  else{
    next()
  }
})

export default router;