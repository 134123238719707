import { render, staticRenderFns } from "./order_submit.vue?vue&type=template&id=290bb96e&scoped=true&"
import script from "./order_submit.vue?vue&type=script&lang=js&"
export * from "./order_submit.vue?vue&type=script&lang=js&"
import style0 from "./order_submit.vue?vue&type=style&index=0&id=290bb96e&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "290bb96e",
  null
  
)

export default component.exports