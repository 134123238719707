<template>
    <div class="container autoHeight">
        <div class="title">
            <h1>热处理加工在线</h1>
        </div>
        <user-info></user-info>   
        <div class="content">
            <el-form label-position="left" ref="searchSupplier" :model="searchSupplier" label-width="90px">
            <el-form-item label="供应商查询">
                <el-col :span="6">
                    <el-input placeholder="请输入供应商名称" v-model="searchSupplier.name" class="input-with-select" width="400px">
                        <el-button slot="append" @click="toSearch()">查询</el-button>
                    </el-input>
                </el-col>
            </el-form-item>
            </el-form>
            <el-dialog title="供应商信息" :visible.sync="dialogTableVisible">
                <el-tag v-for="(item, index) in gridData" :key="index" type="" effect="dark" @click="associate(item.clientIP,item.clientName,item.id)">
                    {{ item.clientName }}
                </el-tag>
            </el-dialog>
            <div id="showData" class="autoHeight">
                <el-tag v-for="(item, index) in associationClient" :key="index" type="" effect="dark" @click="showTabs(item)">
                    {{ item }}
                </el-tag>
            </div>
            <el-tabs v-model="activeName" type="card" @tab-click="handleClick" id='ta' v-show="showBox">
                <el-tab-pane label="热处理进度查询" name="first">
                    <search-progress :clientName="clientName" :activeName="activeName" :clientIp="clientIp"></search-progress>
                </el-tab-pane>
                <el-tab-pane label="热处理订单提交" name="second">
                    <order-submit :clientName="clientName" :activeName="activeName" :clientIp="clientIp"></order-submit>
                </el-tab-pane>
                <el-tab-pane label="账款信息" name="third">
                    <account-Info :clientName="clientName" :activeName="activeName" :clientIp="clientIp"></account-Info>
                </el-tab-pane>
                <el-tab-pane label="问题反馈" name="fourth">
                    <question :clientName="clientName" :activeName="activeName" :clientIp="clientIp"></question>
                </el-tab-pane>
            </el-tabs>
        </div> 
    </div>
</template>

<script>
import userInfo from "@/views/user/user"
import { setStorage,getStorage } from "@/views/newLocalStorage/index.js"
import qs from 'qs'
import SearchProgress from "@/views/progress/search_progress.vue"//进度查询页面
import OrderSubmit from "@/views/progress/order_submit.vue"//订单提交页面
import AccountInfo from "@/views/progress/account_info.vue"//账款信息页面
import Question from "@/views/progress/question.vue"//问题反馈页面
export default {
    data() {
        return {
            phone:this.$store.state.phone,
            searchSupplier:{
                name:""
            },
            activeName: 'first',//标签页默认显示第一页
            showBox:false,//显示标签页
            gridData:[],//供应商数据
            dialogTableVisible:false,//是否显示供应商展示列表
            associationClient:localStorage.getItem("associationClient")?JSON.parse(localStorage.getItem("associationClient")):[],//关联的公司
            clientName:"",//点击的供应商名称
            clientIp:"",//客户ip
        }
    },
    components:{
        userInfo,
        SearchProgress,
        OrderSubmit,
        AccountInfo,
        Question
    },
    methods: {
        //点击按钮搜索供应商
        toSearch:function(){
            if(!this.searchSupplier.name){
                this.$alert('请输入供应商名称进行查询', '商能科技提醒:', {
                    confirmButtonText: '确定'
                });
                return false;
            };
            this.$axios.post(this.$store.state.ip+"/jgzx/getOneClients",qs.stringify({clientName:this.searchSupplier.name})).then(res=>{
                if(res.data.length==0){
                    this.$alert("未搜到该供应商","商能科技提醒您");
                    this.searchSupplier.name = "";
                }else{
                    this.gridData=res.data; 
                    this.dialogTableVisible = true;
                }
            })
        },
        //点击供应商列表,进行关联
        associate:function(ip,clientName,id){
            this.$axios.post(ip+"/checkClientByPhone.action",qs.stringify({phone:this.phone})).then(res=>{
                if(res.data==0){
                    this.$alert("您不是该公司的客户","商能科技提醒您");
                    this.dialogTableVisible = false;
                }else{
                    if(this.associationClient.indexOf(clientName)!=-1){
                        this.$alert("您已关联该公司","商能科技提醒您");
                        this.dialogTableVisible = false;
                        return false;
                    }else{
                        //this.associationClient.push(clientName);
                        this.associationClient.splice(-1,0,clientName)
                        this.dialogTableVisible = false;
                        //长度等于2，说明原本没有关联供应商，1是新增来的
                        if(this.associationClient.length==2){
                            this.$axios.post(this.$store.state.ip+"/jgzx/addAssociationClient",
                                qs.stringify({phone:this.phone,clientNames:this.associationClient[0]})).then(res=>{
                                    console.log("rrrrr",res)
                                if(res.data==1){
                                    this.$alert("关联供应商成功","商能科技提醒您");
                                    localStorage.setItem("associationClient",JSON.stringify(this.associationClient))
                                }else{
                                    this.$alert("添加供应商失败","商能科技提醒您");
                                }
                            }).catch(err=>{
                                this.$alert("添加供应商失败","商能科技提醒您");
                            })
                        }else{
                            this.$axios.post(this.$store.state.ip+"/jgzx/updateAssociationClient",
                                {
                                    id:localStorage.getItem("associationId"),
                                    clientNames:this.associationClient.join(",")
                                },
                            ).then(res=>{
                                if(res.data==1){
                                    this.updateData();
                                }
                            }).catch(err=>{
                                this.$alert("关联失败","商能科技提醒您");
                                return false;
                            })
                        }
                    }
                }

            })
        },
        //刷新供应商列表
        updateData:function(){
            this.$axios({
                url:this.$store.state.ip+"/jgzx/getOneAssociationClient",
                method:"post",
                data:qs.stringify({phone:this.phoneUser})
            }).then(res=>{
                let arr = res.data.clientNames.split(",");
                localStorage.setItem("associationClient",JSON.stringify(arr));
                localStorage.setItem("associationId",res.data.id);
            })
        },
        handleClick(tab, event) {
            let panel = tab.name;
            if(panel == "first"){
                this.$refs.SearchProgress.showPageData();
            }else if(panel == "second"){
                
            }else if(panel == "third"){

            }else if(panel == "fourth"){
                // $.ajax({
                //     type:"post",
                //     dataType:"text",
                //     url:this.clientIp+"/findClientNameByPhoneaction.action",
                //     data:{phone:this.phoneUser}
                // }).then(res=>{
                //     this.getTime();
                //     this.suggest.name = JSON.parse(res).name;
                //     this.suggest.recordMan = JSON.parse(res).name;
                //     this.suggest.originator = JSON.parse(res).name;
                // }).catch(err=>{

                // })
            }
      },
      showTabs:function(name){
            this.$axios({
                url:this.$store.state.ip+"/jgzx/getOneClients",
                method:"post",
                data:qs.stringify({clientName:name})
            }).then(res=>{
                this.clientIp = res.data[0].clientIP;
                this.showBox = true;
                this.clientName = name;
            })
      },
    },
    mounted() {
        document.querySelector("body").setAttribute("style", "background-image:linear-gradient(to right, #efefe , #fff)");
        this.$axios({
                url:this.$store.state.ip+"/jgzx/getOneAssociationClient",
                method:"post",
                data:qs.stringify({phone:this.phone})
            }).then(res=>{
                this.associationClient = res.data.clientNames.split(",");
            })
    },
}
</script>

<style scoped>
    *{
        margin: 0;
        padding: 0;
    }
    .autoHeight{
        position: relative;
        top: 0;
        bottom: 0;
        overflow: hidden;
    }
    .container{
        width: 100%;
    }
    .title{
        width: 100%;
        height: 100px;
        line-height: 100px;
        text-align: center;
    }
    .content{
        width: 80%;
        margin-left: 10%;
    }
    #showData>>>.el-tag{
        width: 14%;
        height: 2.5rem;
        text-align: center;
        margin: 1rem 1%;
        padding: 5px 1rem;
    }
</style>