//设置缓存
function setStorage(key,value,ttl_ms){
    let data = { value: value, expirse: new Date(ttl_ms).getTime() };
    localStorage.setItem(key, JSON.stringify(data));
};
function getStorage(key){
    let data =JSON.parse(localStorage.getItem(key));
    if(data !== null){
        if(data.expirse!=null && data.expirse< new Date().getTime()){
            localStorage.removeItem(key);
        }else{
            return data.value;
        }
    }
    return null
}


export{
    setStorage,
    getStorage
}