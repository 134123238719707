<template>
    <div class="container autoHeight">
        <div class="header">
            <div class="logo">
                <img src="@/assets/image/loginLogo.png" alt="">
            </div>
            <div class="userName">{{ user.userName }}</div>
            <div class="userExit" @click="exit">
                退出
            </div>
        </div>
        <div class="content">
            <div class="left">
                <el-menu default-active="2" class="el-menu-vertical-demo" @select="handleSelect">
                    <el-menu-item index="/pageIndex">
                        <template slot="title">
                            <i class="el-icon-s-home"></i>
                            <span>首页</span>
                        </template>
                    </el-menu-item>
                    <el-submenu index="1">
                        <template slot="title">
                            <i class="el-icon-s-shop"></i>
                            <span>运营管理</span>
                        </template>
                        <el-menu-item-group>
                            <el-menu-item index="/clientInfo">人员信息</el-menu-item>
                            <el-menu-item index="/companyInfo">公司信息</el-menu-item>
                            <el-menu-item index="/supplierInfo">供应商</el-menu-item>
                            <el-menu-item index="/suggestInfo">意见反馈</el-menu-item>
                        </el-menu-item-group>
                    </el-submenu>
                    <el-submenu index="2">
                        <template slot="title">
                            <i class="el-icon-s-platform"></i>
                            <span>系统监控</span>
                        </template>
                        <el-menu-item-group>
                            <el-menu-item index="/logInfo">日志信息</el-menu-item>
                        </el-menu-item-group>
                    </el-submenu>
                    <el-submenu index="3">
                        <template slot="title">
                            <i class="el-icon-s-management"></i>
                            <span>系统管理</span>
                        </template>
                        <el-menu-item-group>
                            <el-menu-item index="/adminInfo">管理员</el-menu-item>
                        </el-menu-item-group>
                    </el-submenu>
                </el-menu>
            </div>
            <div class="right">
                <router-view></router-view>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            user:{
                userName:"",
                userId:""
            }
        }
    },
    mounted() {
        if(localStorage.getItem("jgzxAdmin")){
            var user = JSON.parse(localStorage.getItem("jgzxAdmin"));
            this.user.userName = user.value.name;
            this.user.userId = user.value.id
        }else{
            this.$router.push("/adminLogin");
        }
    },
    methods: {
        exit:function(){
            if(confirm("是否确认退出？")){
                localStorage.removeItem("jgzxAdmin");
                this.$router.go(0);
            }else{
                return false;
            }
        },
        handleSelect:function(key,keyPath){
            this.$router.push(key);
        }
    },
}
</script>

<style scoped>
    *{
        margin: 0;
        padding: 0;
        box-sizing: border-box;
    }
    .container{
        width: 100%;
    }
    .autoHeight{
        position: relative;
        top: 0;
        bottom: 0;
        overflow: hidden;
    }
    .header{
        width: 100%;
        height: 8vh;
        background:#66B1FF;
    }
    .header>div{
        float: left;
    }
    .logo{
        width: 86vw;
        height: 8vh;
        line-height: 8vh;
    }
    .logo img{
        width: 7vh;
        height: 7vh;
        margin: 0.5vh 0 0.5vh 5vw;
    }
    .userName{
        width: 8vw;
        height: 8vh;
        text-align: right;
        line-height: 8vh;
        cursor: pointer;
        font-size: 1.3rem;
    }
    .userExit{
        width: 6vw;
        height: 8vh;
        text-align: center;
        line-height: 8vh;
        font-size: 0.8rem;
        cursor: pointer;
    }
    .content{
        width: 100%;
        height: 92vh;
    }
    .content>div{
        float: left;
    }
    .left{
        width: 15vw;
        height: 92vh;
        border-right: 1px solid #E6E6E6;
    }
    .left>>>.el-menu{
        border-right: none;
    }
    .right{
        width: 85vw;
        height: 92vh;
    }
</style>