<template>
    <div class="container autoHeight">
        <div class="head">
            <div class="head-l">
                <el-row>
                    <el-col :span="6">
                        <div class="head-logo">
                            <img src="@/assets/image/loginLogo.png" alt="">
                        </div>
                    </el-col>
                    <el-col :span="8">
                        <div class="head-userName">
                            <el-select v-model="selectedClient" filterable placeholder="请选择供应商" @change='searchData()'>
                                <template v-for="(item,index) in associationClient">
                                    <el-option v-if="item!=' '" :key="index" :label="item" :value="item"></el-option>
                                    <el-option v-else :label="item" :value="item" style="text-align:center;font-size:1.5rem">
                                        <i class="el-icon-circle-plus-outline"></i>
                                    </el-option>
                                </template>
                            </el-select>
                        </div>
                    </el-col>
                    <!-- <el-col :span="3">
                        <div class="head-nav">首页</div>
                    </el-col>
                    <el-col :span="3">
                        <div class="head-nav">产品服务</div>
                    </el-col>
                    <el-col :span="3">
                        <div class="head-nav">智能制造</div>
                    </el-col> -->
                    <!-- <el-col :span="3">
                        <div class="head-nav">服务支持</div>
                    </el-col> -->
                    <!-- <el-col :span="3">
                        <div class="head-nav">关于我们</div>
                    </el-col> -->
                </el-row>
            </div>
            <div class="head-r">
                <el-row>
                    <el-col :span="10">
                        <div><h1>&nbsp;&nbsp;加&nbsp;&nbsp;工&nbsp;&nbsp;在&nbsp;&nbsp;线&nbsp;&nbsp;</h1></div>
                    </el-col>
                      <el-col :span="5">
                        <div class="head-nav">服务支持</div>
                    </el-col>
                    <el-col :span="9">
                        <div class="head-user">
                            <p @click="exit()">退出</p>
                            <span>
                                登录账号/号码：{{ userMes.account }}/{{userPhone}}
                            </span>
                            <!-- <span style="height:2.8rem;position:relative;top:-0.25rem;">
                                <el-badge :value="3" class="item">
                                    <el-button size="small" style="border:none;">消息</el-button>
                                </el-badge>
                            </span> -->
                        </div>
                    </el-col>
                    <!-- <el-col :span="5">
                        <div class="head-userName">
                            <el-select v-model="selectedClient" filterable placeholder="请选择供应商" @change='searchData()'>
                                <template v-for="(item,index) in associationClient">
                                    <el-option v-if="item!=' '" :key="index" :label="item" :value="item"></el-option>
                                    <el-option v-else :label="item" :value="item" style="text-align:center;font-size:1.5rem">
                                        <i class="el-icon-circle-plus-outline"></i>
                                    </el-option>
                                </template>
                            </el-select>
                        </div>
                    </el-col> -->
                </el-row>
            </div>
        </div>
        <div class="content">
            <el-row>
                <el-col :span="20" class="col2">
                    <el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" @select="handleSelect" background-color="#66B1FF" text-color="#fff" active-text-color="lime">
                        <el-menu-item index="1">进度查询</el-menu-item>
                        <el-menu-item index="2">订单提交</el-menu-item>
                        <el-menu-item index="3">账款信息</el-menu-item>
                        <el-menu-item index="4">问题反馈</el-menu-item>
                    </el-menu>
                </el-col>
                <el-col :span="4" class="col3">
                    <el-button v-if="activeIndex==1" icon="el-icon-search" @click="openDrawer1">搜索</el-button>
                    <el-button v-if="activeIndex==1" type="success"  @click="exportExecl()">导出<i
                class="el-icon-download el-icon--right" ></i></el-button>
                    <el-button v-if="activeIndex==2" @click="addProduct()">添加产品</el-button>
                    <el-button v-if="activeIndex==2" icon="el-icon-search" @click="openDrawer1">搜索</el-button>
                
                    <!-- <el-button v-if="activeIndex==2" @click="subOrders()">提交</el-button> -->
                    <el-button v-if="activeIndex==3">账单回签</el-button>
                    <el-button v-if="activeIndex==3" icon="el-icon-search" @click="openDrawer1">搜索</el-button>
                    <el-button v-if="activeIndex==4" @click="addQuestion()">我要反馈</el-button>
                    <el-button v-if="activeIndex==4" icon="el-icon-search" @click="openDrawer1">搜索</el-button>
                </el-col>
            </el-row>
        </div>
        <div class="content-box">
            <search-progress v-if="activeIndex==1" ref="searchProgress" :clientIp="clientIp" :activeIndex="activeIndex"></search-progress>
            <order-submit v-if="activeIndex==2" ref="orderSubmit" :clientIp="clientIp"></order-submit>
            <account-info v-if="activeIndex==3" ref="AccountInfo" :clientIp="clientIp"></account-info>
            <question v-if="activeIndex==4" ref="Question" :clientIp="clientIp"></question>
        </div>
        <!-- 添加供应商窗口 -->
        <el-dialog title="提示" :visible.sync="dialogVisible" width="30%" :before-close="handleClose">
            <el-form label-position="left" ref="searchSupplier" :model="searchSupplier" label-width="90px">
                <el-form-item label="供应商查询">
                    <el-input placeholder="请输入供应商名称关键词" v-model="searchSupplier.name" class="input-with-select" width="400px">
                        <el-button slot="append" @click="toSearch()">查询</el-button>
                    </el-input>
                </el-form-item>
            </el-form>
            <div id="showData" class="autoHeight2" v-show="supplierTag">
                <el-tag v-for="(item, index) in supplierInfo" style="cursor:pointer;width: auto;" :key="index" type="" effect="dark" @click="associate(item.clientIP,item.clientName,item.id)">
                    {{ item.clientName }}
                </el-tag>
            </div>
        </el-dialog>

        <!-- 进度查询搜索项 -->
        <el-drawer title="进度查询搜索" :visible.sync="drawer1" :with-header="false" size="20%">
            <div v-if="activeIndex==1">
                <h3 class="drawer-title">进度查询搜索</h3>
                <el-form ref="form1" :model="searchForm1" label-width="80px">
                    <el-form-item label="入库批次">
                        <el-input v-model="searchForm1.inCombinationCode"></el-input>
                    </el-form-item>
                    <el-form-item label="产品名称">
                        <el-input v-model="searchForm1.productName"></el-input>
                    </el-form-item>
                    <el-form-item label="出库状态">
                        <el-select v-model="searchForm1.status" placeholder="请选择">
                            <el-option label="所有" value=""></el-option>
                            <el-option label="未出库" value="0"></el-option>
                            <el-option label="未出完" value="1"></el-option>
                            <el-option label="已出完" value="2"></el-option>
                        </el-select>
                    </el-form-item>
                </el-form>
                <center>
                    <el-button type="primary" v-if="activeIndex==1" icon="el-icon-search" @click="searchOrderIns()">搜索</el-button>
                </center>
            </div>
            <div v-if="activeIndex==2">
                <h3 class="drawer-title">订单搜索</h3>
                <el-form ref="form2" :model="searchForm2" label-width="80px">
                    <el-form-item label="入库批次">
                        <el-input v-model="searchForm2.inCombinationCode"></el-input>
                    </el-form-item>
                    <el-form-item label="产品名称">
                        <el-input v-model="searchForm2.productName"></el-input>
                    </el-form-item>
                </el-form>
                <center>
                    <el-button type="primary" v-if="activeIndex==2" icon="el-icon-search" @click="searchOrders()">搜索</el-button>
                </center>
            </div>
            <div v-if="activeIndex==3">
                <h3 class="drawer-title">账款信息搜索</h3>
                <el-form ref="form3" :model="searchForm3" label-width="80px">
                    <el-form-item label="对账月份">
                        <el-date-picker v-model="searchForm3.moneyCheckMonth" value-format="yyyy-MM" type="month" placeholder="选择月份"></el-date-picker>
                    </el-form-item>
                    <el-form-item label="账单编号">
                        <el-input v-model="searchForm3.moneyCheckCode"></el-input>
                    </el-form-item>
                    <!-- <el-form-item label="账单状态">
                        <el-input v-model="searchForm3.feedBackMessage"></el-input>
                    </el-form-item> -->
                </el-form>
                <center>
                    <el-button type="primary" v-if="activeIndex==3" icon="el-icon-search" @click="searchAccount()">搜索</el-button>
                </center>
            </div>
            <div v-if="activeIndex==4">
                <h3 class="drawer-title">反馈搜索</h3>
                <el-form ref="form4" :model="searchForm4" label-width="80px">
                    <el-form-item label="入库批次">
                        <el-input v-model="searchForm4.outCombinationCode"></el-input>
                    </el-form-item>
                    <el-form-item label="反馈内容">
                        <el-input v-model="searchForm4.feedBackMessage"></el-input>
                    </el-form-item>
                </el-form>
                <center>
                    <el-button type="primary" v-if="activeIndex==4" icon="el-icon-search" @click="searchQuestion()">搜索</el-button>
                </center>
            </div>
        </el-drawer>
       <div class="bottombox" >
            <!-- <p>南京商能科技有限公司</p>
            <p>www.shangneng.com.cn</p> -->
            <p>Copyright © 2017-2022 版权归南京商能科技有限公司所有</p>                
                <a href="https://beian.miit.gov.cn">工信部备案号：苏ICP备19052844号-4</a>
        </div>
    </div>
</template> 

<script>
import qs from 'qs'
import {setStorage,getStorage} from '@/views/newLocalStorage/index.js'
import SearchProgress from "@/views/progress/search_progress.vue"//进度查询页面
import OrderSubmit from "@/views/progress/order_submit.vue"//订单提交页面
import AccountInfo from "@/views/progress/account_info.vue"//账款信息页面
import Question from "@/views/progress/question.vue"//问题反馈页面
export default {
    data() {
        return {
            activeName: 'first',
            activeIndex:'1',
            userPhone:"",
            userMes:{},
            selectedClient:"",
            associationClient:[],//关联的公司
            clientIp:'',
            value: '',
            drawer1:false,//进度查询搜索项
            //status:"",//入库状态搜索项
            dialogVisible:false,
            //inCombinationCode:"",//入库批次搜索项
            //productName:"",//入库批次产品名称搜索项
            searchSupplier:{
                name:""
            },
            supplierInfo:[],//供应商信息
            supplierTag:false,
            searchForm1:{//进度查询的搜索项
                inCombinationCode:"",
                productName:"",
                status:""
            },
            searchForm2:{//订单提交的搜索项
                inCombinationCode:"",
                productName:"",
            },
            searchForm3:{//账款信息的搜索项
                moneyCheckMonth:"",
                moneyCheckCode:"",
            },
            searchForm4:{//问题反馈的搜索项
                outCombinationCode:"",
                feedBackMessage:"",
            },
        }
    },
    components:{
        SearchProgress,
        OrderSubmit,
        AccountInfo,
        Question
    },
    methods: {
        exportExecl(){
            this.$refs.searchProgress.exportExecl();
        },
        handleSelect(key, keyPath) {
            this.activeIndex = key;
        },
        handleClick(tab, event) {
            console.log(tab, event);
        },
        handleClose:function(done){
            this.$confirm('确认关闭？')
          .then(_ => {
            done();
            this.selectedClient=''
          })
          .catch(_ => {});
        },
        //进度查询搜索
        searchOrderIns:function(){
            this.$refs.searchProgress.showPageData();
        },
        //订单提交搜索
        searchOrders:function(){
            this.$refs.orderSubmit.getOrderIns();
        },
        //账单回款搜索
        searchAccount:function(){
            this.$refs.AccountInfo.showPageData();
        },
        //问题反馈搜索
        searchQuestion:function(){
            this.$refs.Question.getData();
        },
        addProduct:function(){
            // this.$refs.orderSubmit.addProduct();
            this.$refs.orderSubmit.addProductDialog=true;
        },
        addQuestion:function(){
            this.$refs.Question.addQuestionDialog=true;
        },
        subOrders:function(){
            this.$refs.orderSubmit.saveProduct();
        },
        //点击供应商列表,进行关联
        associate:function(ip,clientName,id){
            this.$axios.post(ip+"/checkClientByPhone.action",qs.stringify({phone:this.userPhone})).then(res=>{
                if(res.data==0){
                    this.$alert("您不是该公司的客户","商能科技提醒您");
                    return false;
                }else{
                    if(this.associationClient.indexOf(clientName)!=-1){
                        this.$alert("您已关联该公司","商能科技提醒您");
                        return false;
                    }else{
                        // this.associationClient.splice(-1,0,clientName);
                        // console.log("2444",this.associationClient.splice(-1,0,clientName));
                        this.associationClient.pop();
                        this.associationClient.push(clientName);
                        this.dialogTableVisible = false;
                        //长度等于1，说明原本没有关联供应商，1是新增来的
                        let id = localStorage.getItem("associationId");
                        let clientNames = this.associationClient.join(",");
                        this.$axios.post(this.$store.state.ip+"/jgzx/updateAssociationClient",
                            qs.stringify({
                                id:id,
                                clientNames:clientNames
                            }),
                        ).then(res=>{
                            if(res.data==1){
                                this.$alert("关联成功","商能科技提醒您");
                                this.dialogVisible=false;
                                localStorage.setItem("associationClient",clientNames);
                                this.associationClient.push(" ");
                                //默认选择刚关联的供应商
                                this.selectedClient = this.associationClient[this.associationClient.length-2];
                                this.searchData();
                                //清空输入的供应商关键词
                                this.searchSupplier.name="";
                                this.supplierInfo=[];
                            }else{
                                this.$alert("您不是该公司的客户，无法关联","商能科技提醒您");
                                this.supplierInfo = [];
                                this.supplierTag=false;
                                this.searchSupplier.name="";
                            }
                        }).catch(err=>{
                            console.log(err);
                            return false;
                        })
                    }
                }

            })
        },
        //点击按钮搜索供应商
        toSearch:function(){
            if(!this.searchSupplier.name){
                this.$alert('请输入供应商名称进行查询', '商能科技提醒:', {
                    confirmButtonText: '确定'
                });
                return false;
            };
            this.$axios.post(this.$store.state.ip+"/jgzx/getOneClients",qs.stringify({clientName:this.searchSupplier.name})).then(res=>{
                if(res.data.length==0){
                    this.$alert("未搜到该供应商","商能科技提醒您");
                    this.searchSupplier.name = "";
                }else{
                    this.supplierInfo=res.data; 
                    this.supplierTag=true;
                }
            })
        },
        //选择供应商，获取对方的花生壳地址
        searchData:function(){
            if(this.selectedClient!=" "){
                this.$axios({
                    url:this.$store.state.ip+"/jgzx/getOneClients",
                    method:"post",
                    data:qs.stringify({clientName:this.selectedClient})
                }).then(res=>{
                    this.clientIp = res.data[0].clientIP;
                    this.$axios({
                        url:this.$store.state.ip+"/log/insertLog",
                        method:"post",
                        data:qs.stringify({
                            userName:this.userPhone,
                            content:"选择供应商<"+this.selectedClient+">查看进度查询",
                            ip:returnCitySN["cip"]
                        })
                    })
                })
            }else{
                this.dialogVisible=true
            }
        },
        exit:function(){
            this.$confirm('是否确认退出?', '商能科技提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
            }).then(() => {
                localStorage.clear();
                this.$router.go(0);
            }).catch(() => {
                return false;         
            });
        },
        openDrawer1:function(){
            this.drawer1 = true;
        },
        stopF5Refresh:function(){
            document.onkeydown = function(e) {
                var evt = window.event || e;
                var code = evt.keyCode || evt.which;
                //屏蔽F1---F12
                if(code>111 &&code<123) {
                    if(evt.preventDefault){
                        evt.preventDefault();
                    }else{
                        evt.keyCode = 0;
                        evt.returnValue = false;
                    }
                }
            };
            //禁止鼠标右键菜单
            document.oncontextmenu = function(e) {
                return false;
            };
            //阻止后退的所有动作，包括 键盘、鼠标手势等产生的后退动作。
            history.pushState(null, null, window.location.href);
            window.addEventListener("popstate",function(){
                history.pushState(null, null, window.location.href);
            });
        },
    },
    mounted() {
        this.userPhone = getStorage("userPhone");
        this.userMes= getStorage("userMes");
    },
    created() {
        if(localStorage.getItem("associationClient")==""){
            this.associationClient.push(" ");
        }else{
            this.associationClient = localStorage.getItem("associationClient").split(",");
            this.associationClient.push(" ");
        };
        if(this.associationClient.length==2){
            this.selectedClient = this.associationClient[0];
            this.searchData();
        };
        this.stopF5Refresh();
    },
    watch: {
        status(val,old){
            this.$refs.searchProgress.showPageData();
        }
    },
}
</script>

<style scoped>
    /* *{
        margin: 0;
        padding: 0;
    } */
    .container{
        width: 100%;
    }
    .autoHeight{
        position: relative;
        top: 0;
        bottom: 0;
        overflow: hidden;
    }
    .autoHeight2{
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
    }
    .head{
        width: 100%;
        height: 3.5rem;
        line-height: 3.5rem;
    }
    .head>div{
        float: left;
    }
    .head-l{
        width: 35%;
        height: 3.5rem;
    }
    .head-nav{
        font-size: 0.9rem;
        text-align: center;
        cursor: pointer;
        font-weight: bold;
    }
    .head-nav:hover{
        font-weight: bold;
        color: #fff;
        background: powderblue;
    }
    .head-logo>img{
        float: right;
        height: 3rem;
        margin-top: 0.25rem;
        margin-right: 2rem;
    }
    .head-r{
        width: 65%;
        height: 3.5rem;
        font-weight: bold;
        text-align: center;
    }
    .head-user p{
        display: inline-block;
        font-size: 0.8rem;
        font-weight: normal;
        height: 3.5rem;
        line-height: 3.5rem;
        cursor: pointer;
    }
    .head-user img{
        width: 1.5rem;
        float: left;
        margin-top: 1rem;
        cursor: pointer;
    }
    .head-user span{
        height: 1.5rem;
        float: left;
        line-height: 1.5rem;
        margin-top: 1.2rem;
    }
    .content{
        width: 100%;
        height: auto;
        background: #66B1FF;
    }
    .el-dropdown-link {
        cursor: pointer;
    }
    .el-icon-arrow-down {
        font-size: 12px;
    }
    .demonstration {
        display: block;
        color: #8492a6;
        font-size: 14px;
        margin-bottom: 20px;
    }
    .dd>>>.el-dropdown-item{
        width: 5rem;
        height: 3rem;
        padding: 2rem;
    }
    .col1,.col2,.col3{
        height: 4rem;
        line-height: 4rem;
    }
    .col2>>>.el-menu{
        height: 4rem;
    }
    .el-menu>>>.el-menu-item{
        font-size: 1.3rem;
        margin: 0 2.5rem;
    }
    .el-menu>>>.el-menu-item:first-child{
        margin-left: 5rem;
    }
    .col3>>>.el-input{
        width: 9rem;
    }
    .col3>>>.el-button{
        width: 6rem;
        height: 2.5rem;
    }
    .content-box{
        width: 98%;
        height: 82vh;
        box-sizing: border-box;
        margin-left: 1%;
        border: 3px solid #ccc;
        border-top: 0px;
    }
    #showData>>>.el-tag{
        width: 30%;
        height: 2.5rem;
        text-align: center;
        margin: 1rem 1%;
        padding: 5px 1rem;
    }
    .container>>>.el-drawer{
        padding-top: 5vh;
        outline: none;
    }
    .drawer-title{
        text-align: center;
        margin-bottom: 30px;
    }
    .bottombox{
  background-color: beige;
        bottom: 0;
        width: 100%;
        height: 4rem;       
        cursor: pointer;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .bottombox>p:first-child{
        font-size: 0.8rem;
        text-align: center;
        margin-top: 0.5rem;
    }
    .bottombox>a:last-child{
        text-align: center;
        font-size: 0.8rem;
        margin-top: 0.5rem;
    }
</style>