<template>
    <div class="container">
        <div class="searchBar">
            <el-date-picker v-model="search.beginTime" type="datetime" @change="getData" value-format="yyyy-MM-dd HH:mm:ss" placeholder="选择开始时间"></el-date-picker>
            <el-date-picker v-model="search.endTime" type="datetime" @change="getData" value-format="yyyy-MM-dd HH:mm:ss" placeholder="选择结束时间"></el-date-picker>
            <el-input placeholder="请输入内容" v-model="search.content" clearable style="width:200px;" @input="getData" @clear="getData"></el-input>
            <el-button type="primary" icon="el-icon-search" @click="getData">搜索</el-button>
        </div>
        <el-table :data="tableData" border style="width: 100%" height="82.5vh">
            <el-table-column prop="time" label="时间" fixed width="160" header-align="center"></el-table-column>
            <el-table-column prop="ip" label="ip" width="160" header-align="center"></el-table-column>
            <el-table-column prop="userName" label="操作人" width="130" header-align="center"></el-table-column>
            <el-table-column prop="content" label="操作内容" header-align="center"></el-table-column>
        </el-table>
        <div class="footer">
            <el-pagination background layout="prev, pager, next" :total="total" @current-change="changePage" :page-size="20" @prev-click="changePage" @next-click="changePage"></el-pagination>   
        </div>
    </div>
</template>

<script>
import qs from 'qs'
export default {
    data() {
        return {
            search:{
                beginTime:"",
                endTime:"",
                content:"",
                index:1
            },
            total:20,//数据的总条数
            tableData: []
        }
    },
    methods: {
        getData:function(){
            this.$axios({
                url:this.$store.state.ip+"/log/getAllLogs",
                method:"post",
                data:qs.stringify(this.search)
            }).then(res=>{
                this.tableData = res.data.rows;
                this.total = res.data.count;
                this.pageSize = res.data.rows.length;
            })
        },
        changePage:function(page){
            this.search.index = page;
            this.getData();
        }
    },
    mounted() {
        this.getData();
    },
}
</script>

<style scoped>
    .container{
        width: 100%;
        height: 92vh;
    }
    .searchBar{
        margin-top: 3px;
        height: 5vh;
        margin-bottom: 2px;
    }
    .footer{
        text-align: center;
    }
</style>