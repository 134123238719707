<template>
    <div class="container">
        <div class="leftbox" ></div>
        <div class="rightbox">
            <el-form ref="form" :model="form" label-width="80px" class="login-form" v-if="showLogin">
                <h2 class="login-title">加工在线系统</h2>
                <el-form-item label="手机号" v-show="radio==1">
                    <el-input placeholder="请输入手机号" v-model="form.username" id="phone" class="input-with-select" maxlength=11>
                        <el-button slot="append" @click="checkPhone($event)">验证</el-button>
                    </el-input>
                </el-form-item>
                <el-form-item label="验证码" v-if="showCodeInput">
                    <el-input v-model="phoneCode" placeholder="请输入验证码" id="code"></el-input>
                </el-form-item>
                <el-form-item label="用户名" v-show="radio==2">
                    <el-input v-model="form.username" placeholder="请输入账号"></el-input>
                </el-form-item>
                <el-form-item label="密码" v-show="radio==2">
                    <el-input v-model="form.password" placeholder="请输入密码" show-password></el-input>
                </el-form-item>
                <el-form-item class="login-item">
                    <el-button type="primary" @click="onSubmit">登录</el-button>
                </el-form-item>
                <el-form-item class="more">
                    <el-radio v-model="radio" label="1">手机登录</el-radio>
                    <el-radio v-model="radio" label="2">账号登录</el-radio>
                    <el-link type="primary" v-if="radio==2" :underline="underline" @click="toReg">注册</el-link>
                </el-form-item>
            </el-form>
        </div>
        <div class="topbox">
            <img src="@/assets/image/loginLogo.png" alt="">
            <span>加工在线</span>
            <span id="uploadImg" @click="dialogVisible = true">二维码识别</span>
        </div>
        <div class="bottombox" >
            <!-- <p>南京商能科技有限公司</p>
            <p>www.shangneng.com.cn</p> -->
            <p>Copyright © 2017-2024 版权归南京商能科技有限公司所有</p>                
                <a href="https://beian.miit.gov.cn">工信部备案号：苏ICP备19052844号-4</a>
        </div>
        <!-- 注册窗口 -->
        <transition name="el-zoom-in-center">
            <div class="regBox"  v-if="showReg">
                <div class="reg">
                    <div class="reg-titleBox">
                        <div class="reg-title">加工在线注册</div>
                        <div class="reg-cancel" @click="cancelReg">X</div>
                    </div>
                    <div class="reg-content">
                        <el-input v-show="!nextStep" v-model="user.account" placeholder="账号" @blur="checkAccount" show-word-limit maxlength="16"></el-input>
                        <el-input v-show="!nextStep" v-model="user.phone" placeholder="手机号"></el-input>
                        <el-input v-show="!nextStep" v-model="user.code" @input="checkRegPhoneOk" @blur="leaveCode" class="userCodeInput" placeholder="验证码"></el-input>
                        <el-button v-show="!nextStep" class="userCode" @click="checkRegPhone($event)">获取验证码</el-button>
                        <el-input v-show="!nextStep" type="password" show-password v-model="user.password" placeholder="密码" maxlength="16"></el-input>
                        <el-input v-show="!nextStep" type="password" @input="checkPwd" @blur="leavePwd" show-password v-model="user.repassword" placeholder="密码确认" maxlength="16"></el-input>
                        <el-input v-show="nextStep" v-model="user.companyName" placeholder="公司名称"></el-input>
                        <el-select v-show="nextStep" v-model="user.vocation" class="vocation" @change="getVocation" filterable placeholder="所属行业">
                            <el-option v-for="item in vocationData" :key="item.id" :label="item.name" :value="item.name"></el-option>
                        </el-select>
                        <el-select v-show="nextStep" v-model="user.provinceId" class="province" @change="getCity" filterable placeholder="所在省份">
                            <el-option v-for="item in provinceData" :key="item.id" :label="item.name" :value="item.id"></el-option>
                        </el-select>
                        <el-select v-show="nextStep" v-model="user.cityId" class="city" filterable placeholder="所在城市" @change="getCityName">
                            <el-option v-for="item in cityData" :key="item.id" :label="item.name" :value="item.id"></el-option>
                        </el-select>
                        <!-- <el-input v-show="nextStep" v-model="user.repwd" placeholder="邀请码"></el-input> -->
                        <el-button v-show="!nextStep" type="warning" @click="toNext()">下一步</el-button>
                        <el-button v-show="nextStep" type="warning" @click="finish()">注册</el-button>
                    </div>
                </div>
            </div>
      </transition>
        <!-- 上传窗口 -->
        <el-dialog title="图片上传" :visible.sync="dialogVisible" width="50%" :before-close="handleClose">
            <el-upload
                action="http://36.152.120.150/jgzx/jgzx/uploadFile.action"
                list-type="picture-card"
                name="myFile"
                accept="image/*"
                ref="upload"
                :on-preview="handlePictureCardPreview"
                :on-success="uploadSuccess"
                :on-remove="handleRemove">
                <i class="el-icon-plus"></i>
                </el-upload>
                <el-dialog :visible.sync="dialogImgVisible">
                <img width="100%" :src="dialogImageUrl" alt="">
            </el-dialog>
        </el-dialog>
        <!-- 上传成功，信息展示 -->
        <el-dialog title="上传成功" :visible.sync="dialogVisibleShow" width="30%">
            <iframe :src="iframe" style="margin-top:-30px;" height="100%" width="100%" scrolling="auto" frameborder="0"></iframe>
        </el-dialog>
    </div>
</template>

<script>
import qs from "qs"
// import { provinceAndCityDataPlus, regionDataPlus, CodeToText, TextToCode } from 'element-china-area-data'
import {setStorage,getStorage} from '@/views/newLocalStorage/index.js'
export default {
    data() {
        return {
            form:{
                userName:"",
                password:""
            },
            radio:"2",
            underline:false,
            countDown:60,
            showCodeInput:false,
            phone:"",
            nextStep:false,
            phoneCode:"",
            messageToken:"",
            showReg:false,
            showLogin:true,
            dialogVisible: false,
            dialogVisibleShow:false,
            dialogImageUrl: '',
            dialogImgVisible: false,
            iframe:"",
            user:{
                phone:"",//注册电话
                code:"",//注册验证码
                account:"",//注册账号
                password:"",//注册密码
                repassword:"",//注册确认密码
                companyName:"",//注册公司
                vocation:"",//注册所在行业
                provinceId:"",//所在省份id
                province:"",//所在省份
                cityId:"",//所在城市id,
                city:"",//所在城市id
                messageToken:"",
                // invisiteCode:""//注册邀请码
            },
            provinceData:[],//所在省市数组
            cityData:[],//所在城市数组
            vocationData:[],//所属行业数组
            accountOk:false,//记录账户是否存在的状态，为true才能点击下一步,
            phoneOk:false,//记录验证注册手机号，为true才能进行下一步,
            passwordOk:false,//记录二次验证密码是否正确,为true才能进行下一步
            vocation:""
        }
    },
    methods: {

        handleClose(done) {
            this.$confirm('确认关闭？')
            .then(_ => {
                done();
            })
            .catch(_ => {});
        },
        toNext:function(){
            if(this.accountOk && this.phoneOk && this.passwordOk){
                this.getProvince();
                this.getVocation();
                this.nextStep=true;
            }else{
                alert("请先写正确的信息");
                this.nextStep = false;
                return false;
            }
        },
        finish:function(){
            if(this.user.companyName==""){
                alert("请填写公司名称");
                return false;
            }
            if(this.user.vocation==""){
                alert("请选择所属行业");
                return false;
            }
            if(this.user.province=="" || this.user.city==""){
                alert("请选择省市");
                return false;
            }

            this.$axios.post(
                this.$store.state.ip+"/jgzx/addOneAssociationClient",
                qs.stringify(this.user)
            ).then(res=>{
                this.open();
            }).catch(err=>{
                console.log(err);
            })

            
        },
        open() {
            this.$confirm('注册完成，是否直接登录?', '商能科技提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
            }).then(() => {
                this.cancelReg();
                this.form.username=this.user.account;
                this.form.password=this.user.password;
                this.nextStep = false;
                this.countDown = 60;
                for (let key in this.user) {
                    this.user[key]=""
                } 
            }).catch(() => {
                this.cancelReg();
                this.nextStep = false;
                this.countDown = 60;
                for (let key in this.user) {
                    this.user[key]=""
                }         
            });
        },
        cancelReg:function(){
            this.showReg=false;
            this.showLogin = true;
        },
        handleRemove(file, fileList) {
            console.log(file, fileList);
        },
        uploadSuccess(response, file, fileList){
            if(response){
                this.dialogVisibleShow = true;
                this.iframe=response
            }else{
                alert("请上传正确的二维码");
                this.$refs.upload.clearFiles();
                return false;
            }
        },
        handlePictureCardPreview(file) {
            this.dialogImageUrl = file.url;
            this.dialogImgVisible = true;
        },
        toReg:function(){
            this.showReg = true;
            this.showLogin=false;
        },
        //验证手机号
        checkPhone:function(e){
            let phone = document.querySelector("#phone").value;
            if(!(/^1[3456789]\d{9}$/.test(phone))){
                alert("手机号码有误，请重新填写");
                return false;
            }else{
                let obj =e.currentTarget;
                obj.innerText = this.countDown;
                let timer = setInterval(()=>{
                    this.countDown--;
                    obj.innerText = this.countDown;
                    if(this.countDown<=0){
                        clearInterval(timer);
                        this.countDown = 60;
                        obj.innerText = "重新发送";
                        obj.removeAttribute("disabled");
                    }else{
                        obj.setAttribute("disabled",true);
                    }
                },1000);
                this.$axios.post(this.$store.state.ip+"/jgzx/getVerificationCode",qs.stringify({phone:phone,type:3})).then(data=>{
                    this.phone = phone;
                    this.countDown = 60;
                    this.showCodeInput=true;
                    this.messageToken = data.data;
                    console.log("data",data);
                }).catch(err=>{
                    console.log(err);
                })
            }
        },
        toCompany:function(){
            window.open("http://www.shangneng.com.cn", '_blank');
        },
        //登录
        onSubmit:function(){
            //直接手机验证登录
            if(this.radio==1){
                let phone = this.phone;
                let code = document.querySelector("#code").value;
                this.$axios.post(
                    this.$store.state.ip+"/jgzx/checkVerificationCodeLogin",
                    qs.stringify({
                        phone:phone,
                        phoneCode:code,
                        messageToken:this.messageToken,
                    }),
                    {
                        withCredentials:true
                    }
                ).then(res=>{
                    console.log(res);
                    if(res.data.check==true){
                        var date = new Date().getTime();
                        setStorage("userPhone",this.phone,date+86400000);//有效期一天
                        setStorage("userToken",this.messageToken,date+86400000);//有效期一天
                        this.$store.commit("setToken",this.messageToken);
                        this.$store.commit("setPhone",this.phone);
                        localStorage.setItem("associationId",res.data.object.id)
                        // setStorage("associationClient",res.data.object.clientNames,date+86400000);//有效期一天
                        localStorage.setItem("associationClient",res.data.object.clientNames)
                        // let date = new Date().getTime();
                        this.$router.push("/")
                    }else{
                        alert("验证码错误");
                        return false;
                    }
                }).catch(err=>{
                    console.log("验证码出现错误",err)
                })
            }else if(this.radio==2){//密码登录
                if(this.form.username=="" || this.form.password==""){
                    alert("请填写账号和密码");
                    return false;
                }
                this.$axios.post(
                    this.$store.state.ip+"/jgzx/loginJgzx",
                    qs.stringify({
                        account:this.form.username,
                        password:this.form.password
                    })
                ).then(res=>{
                    // console.log("ssss",res);
                    // return false;
                    if(res.data.check==true){
                        var date = new Date().getTime();
                        setStorage("userMes",res.data.object,date+86400000);//有效期一天
                        setStorage("userPhone",res.data.object.phone,date+86400000);//有效期一天
                        setStorage("userToken",this.messageToken,date+86400000);//有效期一天
                        // setStorage("associationId",res.data.object.id,date+86400000);//有效期一天
                        localStorage.setItem("associationId",res.data.object.id)
                        // setStorage("associationClient",res.data.object.clientNames,date+86400000);//有效期一天
                        localStorage.setItem("associationClient",res.data.object.clientNames)
                        this.$store.commit("setToken",this.messageToken);
                        this.$store.commit("setPhone",res.data.object.phone);
                        this.$store.commit("setUserMes",res.data.object);
                        // let date = new Date().getTime();
                        this.$router.push("/")
                    }else{
                        alert(res.data.message);
                        return false;
                    }
                }).catch(err=>{
                    console.log("验证码出现错误",err)
                })
            }
        },
        getProvince:function(){
            this.$axios.post(
                this.$store.state.ip+"/jgzx/allProvince",
            ).then(res=>{
                this.provinceData = res.data;
            })
        },
        getCity:function(val){
            this.user.city="";
            this.user.cityId = "";
            let obj = {};
            obj = this.provinceData.find((item)=>{//遍历list的数据
                return item.id === val;//筛选出匹配数据
            });
            this.user.province = obj.name;
            this.$axios.post(
                this.$store.state.ip+"/jgzx/allCity",
                qs.stringify({provinceId:this.user.provinceId})
            ).then(res=>{
                this.cityData = res.data;
            })
        },
        getCityName:function(val){
            let obj = {};
            obj = this.cityData.find((item)=>{//遍历list的数据
                return item.id === val;//筛选出匹配数据
            });
            this.user.city = obj.name;
        },
        getVocation:function(val){
            // this.user.vocation="";
            this.$set(this.user, this.user.vocation, val)
            this.$axios.post(
                this.$store.state.ip+"/jgzx/allVocation",
            ).then(res=>{
                this.vocationData = res.data;
            })
        },
        checkAccount:function(){
            this.$axios.post(
                this.$store.state.ip+"/jgzx/getAssociationClientByAccount",
                qs.stringify({account:this.user.account})
            ).then(res=>{
                //返回值为false则说明不存在可以使用
                if(res.data == true){
                    this.accountOk = true;
                }else{
                    alert("账号已存在，请重新填写");
                    this.accountOk = false;
                }
            })
        },
        checkRegPhone:function(e){
            let phone = this.user.phone;
            if(!(/^1[3456789]\d{9}$/.test(phone))){
                alert("手机号码有误，请重新填写");
                return false;
            }else{
                let obj =e.currentTarget;
                obj.innerText = this.countDown;
                let timer = setInterval(()=>{
                    this.countDown--;
                    obj.innerText = this.countDown;
                    if(this.countDown<=0){
                        clearInterval(timer);
                        this.countDown = 60;
                        obj.innerText = "重新发送";
                        obj.removeAttribute("disabled");
                    }else{
                        obj.setAttribute("disabled",true);
                    }
                },1000);
                this.$axios.post(this.$store.state.ip+"/jgzx/getVerificationCode",qs.stringify({phone:phone,type:3})).then(res=>{
                    this.countDown = 60;
                    this.user.messageToken = res.data
                }).catch(err=>{
                    console.log(err);
                })
            }
        },
        checkRegPhoneOk:function(){
            let phone = this.user.phone;
            let code = this.user.code;
            let messageToken = this.user.messageToken;
            if(code.length==6){
                this.$axios.post(
                    this.$store.state.ip+"/jgzx/checkVerificationCode",
                    qs.stringify({
                        phone:phone,
                        phoneCode:code,
                        messageToken:messageToken,
                    }),
                    {
                        withCredentials:true
                    }
                ).then(res=>{
                    if(res.data==true){
                        alert("验证成功");
                        this.phoneOk = true
                    }else{
                        alert(res.data.message);
                        return false;
                    }
                }).catch(err=>{
                    console.log("验证码出现错误",err)
                })
            }else{
                this.phoneOk = false;
            }
        },
        leaveCode:function(){
            // if(!this.phoneOk){
            //     alert("验证手机号出错，请重新验证");
            //     return false;
            // }
        },
        checkPwd:function(){
            if(this.user.password!=this.user.repassword){
                this.passwordOk = false;
            }else{
                this.passwordOk = true;
            }
        },
        leavePwd:function(){
            if(!this.passwordOk){
                alert("确认密码与密码不同，请重新填写");
                this.passwordOk = false;
                return false;
            }
        },
    },
    mounted(){
        
        let self = this;
        this.$nextTick(function () {
            document.addEventListener('keyup', function (e) {
                //此处填写你的业务逻辑即可
                if (e.keyCode == 27) {
                    self.cancelReg();
                }else if(e.keyCode== 13){
                    self.onSubmit();
                }
            })
        });
    }
}
</script>

<style scoped>
    .container{
        width: 100%;
        height: 100%;
        position: absolute;
        background: url("~@/assets/image/bg.jpg") no-repeat;
        background-size:100% 100%;
        background-attachment:fixed;
    }
    .container>div{
        float: left;
    }
    .leftbox{
        width: 50%;
        height: 500px;
        background: transparent;
    }
    .rightbox{
        width: 50%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .topbox{
        position: fixed;
        top: 0;
        width: 100%;
        height: 6rem;
        background: rgba(100, 139, 237,0.4);
    }
    .topbox>img{
        float: left;
        width: 5rem;
        height: 5rem;
        margin-top: 0.5rem;
        margin-left: 8rem;
    }
    .topbox>span{
        float:left;
        height: 5rem;
        color: #000;
        margin-top: 0.5rem;
        padding-left: 2rem;
        font-size: 2rem;
        line-height: 5rem;
        display: inline-block;
    }
    .bottombox{
        position: fixed;
        bottom: 0;
        width: 100%;
        height: 6rem;
        /* color: #fff; */
        background-image: linear-gradient(to right,#2C55A5,teal,#2D5DA9);
        opacity: 0.9;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .bottombox>p:first-child{
        font-size: 1rem;
        text-align: center;
        margin-top: 1rem;
    }
    .bottombox>p:last-child{
        text-align: center;
        margin-top: 0.5rem;
    }
    .login-form{
        width: 360px;
        background-color: rgb(255, 255, 255, 0.8);
        padding: 30px;
        border-radius: 20px;
    }
    .login-title {
        color: #303133;
        text-align: center;
        margin-bottom: 20px;
    }
    .more>>>.el-button{
        text-align: left;
    }
    .login-form>>>.login-item{
        text-align: center;
    }
    .autoHeight{
        position: relative;
        top:0;
        bottom: 0;
        overflow: hidden;
    }
    #uploadImg{
        float: right;
        font-size: 1rem;
        font-weight: bold;
        margin-right: 5%;
    }
    .regBox{
        position: fixed;
        width: 100vw;
        height: 100vh;
        background: rgba(0, 0, 0,0.3);
        z-index: 888;
    }
    .reg{
        position: fixed;
        width: 20vw;
        left: 40vw;
        top: 15vh;
        border-radius: 10px;
        outline: none;
        z-index: 999;
        background: rgba(255,255,255,0.3);
    }
    .reg-titleBox{
        clear: both;
        opacity: 1;
        box-sizing: border-box;
        height: 5vh;
        background: #fff;
        border-top-right-radius: 10px;
        border-top-left-radius: 10px;
    }
    .reg-titleBox>div{
        float: left;
        text-align: center;
        line-height: 5vh;
        height: 5vh;
        outline: none;
    }
    .reg-title{
        width: 80%;
        margin-left: 10%;
        font-weight: bold;
    }
    .reg-cancel{
        width: 10%;
        cursor: pointer;
        border-top-right-radius: 10px;
    }
    .reg-content>>>.el-input,.reg-content>>>.el-button{
        float: left;
        width: 80%;
        margin: 10px 10%;
    }
    .userCodeInput{
        width: 45% !important;
        margin:10px 0 10px 10% !important;
    }
    .userCode{
        width: 33% !important;
        margin:10px 0 10px 2% !important;
    }
    .reg-content>>>.el-select{
        float: left;
        width: 40%;
    }
    .reg-content>>>.vocation{
        width: 80% !important;
        margin-left: 10%;
    }
    
    .province{
        margin-left: 10%;
    }
    .province>>>.el-input,.city>>>.el-input,.vocation>>>.el-input{
        width: 100%;
        float: left;
        margin-left: 0;
    }
</style>