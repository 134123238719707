<template>
    <div class="container">
       <div class="searchBar">
            <el-input placeholder="供应商名称" v-model="search.clientName" clearable style="width:200px;" @input="getData" @clear="getData"></el-input>
            <el-button type="primary" icon="el-icon-search" @click="getData">搜索</el-button>
            <el-button type="primary" icon="el-icon-plus" @click="addSupplier">新增</el-button>
        </div>
        <el-table :data="tableData" border style="width: 100%" height="82.5vh">
            <el-table-column prop="clientName" label="供应商名称" fixed width="200" header-align="center">
                <template slot-scope="{row,$index}">
                    <el-input v-model="row.clientName" v-if="showEdit[$index]"></el-input>
                    <span v-if="!showEdit[$index]">{{row.clientName}}</span>
                </template>
            </el-table-column>
            <el-table-column prop="clientIP" label="外网地址" header-align="center">
                <template slot-scope="{row,$index}">
                    <el-input v-model="row.clientIP" v-if="showEdit[$index]"></el-input>
                    <span v-if="!showEdit[$index]">{{row.clientIP}}</span>
                </template>
            </el-table-column>
            <el-table-column prop="invitationCode" label="邀请码" width="130" header-align="center"></el-table-column>
            <el-table-column label="操作" width="200" header-align="center">
                <template slot-scope="{row,$index}">
                    <el-button type="primary" size="small" @click.native="handleUpdate($index, row)" v-if="showBtn[$index]">保存</el-button>
                    <el-button type="warning" size="small" @click.native="handleCancel($index, row)" v-if="showBtn[$index]">取消</el-button>
                    <el-button type="primary" size="small" @click.native="handleEdit($index, row)" v-if="!showBtn[$index]">编辑</el-button>
                    <el-button type="danger" size="small" @click.native="handleDelete($index, row)" v-if="!showBtn[$index]">删除</el-button>
                </template>
            </el-table-column>
        </el-table>
        <div class="footer">
            <el-pagination background layout="prev, pager, next" :total="total" @prev-click="changePage" @next-click="changePage"></el-pagination>   
        </div>
        <el-dialog title="新增供应商" :visible.sync="toAddSupplier" width="30%" :before-close="handleClose">
            <el-form ref="form" :model="addForm" label-width="80px">
                <el-form-item label="供应商名称">
                    <el-input v-model="addForm.clientName"></el-input>
                </el-form-item>
                <el-form-item label="外网地址">
                    <el-input v-model="addForm.clientIP"></el-input>
                </el-form-item>
            </el-form>
            <center>
                <el-button type="primary" icon="el-icon-success" @click="saveSupplier">保存</el-button>
            </center>
        </el-dialog>
    </div>
</template>

<script>
import qs from 'qs'
export default {
    data() {
        return {
            search:{
                clientName:"",
                index:1
            },
            addForm:{
                clientName:"",
                clientIP:""
            },
            total:1,
            tableData: [],
            showEdit: [], //显示编辑框
            showBtn: [],
            showBtnOrdinary: true,
            toAddSupplier:false
        }
    },
    methods: {
        getData:function(){
            this.$axios({
                url:this.$store.state.ip+"/jgzx/getAllErpClients",
                method:"post",
                data:qs.stringify(this.search)
            }).then(res=>{
                this.tableData = res.data.rows;
                this.total = res.data.totalPage;
            }).catch(err=>{
                console.log(err);
            })
        },
        addSupplier:function(){
            this.toAddSupplier = true
        },
        saveSupplier:function(){
            this.$axios({
                url:this.$store.state.ip+"/jgzx/insertErpClient",
                method:"post",
                data:qs.stringify(this.addForm)
            }).then(res=>{
                if(res.data==1){
                    alert("新增成功");
                    this.getData();
                    this.addForm.clientName="";
                    this.addForm.clientIP="";
                    this.toAddSupplier = false;
                }else{
                    alert("新增失败");
                }
            }).catch(err=>{
                console.log(err);
            })
        },
        handleClose:function(done){
            this.$confirm('确认关闭？').then(_ => {
                done();
            }).catch(_ => {});
        },
        changePage:function(page){
            this.search.index = page;
            this.getData();
        },
        handleEdit:function(index,row){
            this.showEdit[index]=true;
            this.showBtn[index]=true;
            this.$set(this.showEdit,row,true)
            this.$set(this.showBtn,row,true)
        },
        //取消编辑
        handleCancel:function(index,row){
            this.getData();
            this.showEdit=[];
            this.showBtn=[];
            this.showBtnOrdinary=true
        },
            
        //点击更新
        handleUpdate:function(index,row){
            this.$axios({
                url:this.$store.state.ip+"/jgzx/updateErpClient",
                method:"post",
                data:qs.stringify({
                    id:row.id,
                    clientName:row.clientName,
                    clientIP:row.clientIP
                })
            }).then(res=>{
                if(res.data==1){
                    alert("编辑成功");
                    this.handleCancel();
                }else{
                    alert("编辑失败");
                    return false;
                }
            }).catch(err=>{
                console.log(err);
            })
        },
        //点击删除
        handleDelete:function(index, row) {
            this.$confirm('确认删除？').then(_ => {
                this.$axios({
                    url:this.$store.state.ip+"/jgzx/deleteErpClient",
                    method:"post",
                    data:qs.stringify({
                        id:row.id,
                        erpName:row.clientName
                    })
                }).then(res=>{
                    if(res.data==1){
                        alert("删除成功");
                        this.getData();
                    }else{
                        alert("删除失败");
                        return false;
                    }
                }).catch(err=>{
                    console.log(err);
                })
            }).catch(_ => {});
        },
    },
    mounted() {
        this.getData();
    },
}
</script>

<style scoped>
    .container{
        width: 100%;
        height: 92vh;
    }
    .searchBar{
        margin-top: 3px;
        height: 5vh;
        margin-bottom: 2px;
    }
    .footer{
        text-align: center;
    }
</style>